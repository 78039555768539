<template>
  <div id="app">
      
      <main :class="[myWidth == 'sm' ? 'pb-9' : '', ]" class="pb-3" >
        <AppHeader :searchVisible='searchVisible' :class="$route.name != 'Home' ? ['nav-sec', ''] : 'nav-layout' "/>
        <!-- v-if="$route.name != 'BusinessProfile'" -->
        <div  class="container-fluid pb-4" v-if="!businessHome" :class="$route.name != 'Home' ? ['nav-sec', ''] : '' ">
            <form @submit.prevent='search(text)' class="row justify-content-center" v-if="profileRouteBtn() ">
             
             <div class="search-contain-sec col-sm-8">
               <div class="input-group">
                <input 
                    :class=" locale == 'ar' ? 'text-right' : 'text-left'"
                    class='form-control home-text-input' 
                    v-model='text'
                    :placeholder="$t('line1')"  
                  />
                <div class="input-group-append">
                  <button class="btn btn-icon-sec-square m-0" style="width: 70px;height: 48px"  type="submit" id="button-addon1"><i class="fa fa-search"></i></button>               
                </div>
              </div>
             </div>
                
            </form>
          </div>

          <router-view  :searchForm='searchForm'/>
       

        <Msg :class="myWidth == 'sm' ? 'pt-8' : ''" :showModal='showModal'/>

        <div class="modal fade pb-9" id="appmodal">
          <div class="mx-2 modal-dialog w-100" :class='myWidth == "sm" ? "w-100" : ["modal-xl", "mx-auto"]'>
            <div class="modal-content filter-contain" :class="myWidth != 'sm' ? 'px-3' : 'px-1'">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="">
                  <DownloadApp v-if='myWidth != "sm" && cookiesModal == false'/>
                  <CookiesModal v-if='cookiesModal == true' />
                </div>
              </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
          </div><!-- /.modal -->
      </main>
      <footer>
        <AppFooter v-if="myWidth != 'sm'"/>
        <div v-else class="fixed-footer">
          <DownloadApp />
        </div>
      </footer>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import DownloadApp from '@/components/misc/DownloadApp.vue';
import Msg from '@/components/misc/msg.vue';
import CookiesModal from '@/components/misc/CookiesModal.vue';
import cookies from 'vue-cookies';
import {pluginOptions} from "/vue.config"

export default {
  components: {
    AppHeader,
    AppFooter,
    Msg,
    DownloadApp,
    CookiesModal
  },

 
  data() {
    return {
      locales: this.$i18n.availableLocales,
      width: window.innerWidth,
      Rtl: this.$i18n.locale == 'ar',
      text: '',
      city: '',
      searchForm: false,
    }
  },
  watch: {
    locale: function(newlocale) {
      if(this.businessHome && this.businessHome != null) {
        cookies.set(`${this.businessHome.style}_lang`, newlocale)
      }else{
        cookies.set('lang', newlocale);
      }
        if(newlocale == 'ar'){
            this.Rtl = true;
        }else {
            this.Rtl = false;
        }
    },
    
  },
  created() {
    if(this.businessHome !== null) {
      const favicon = document.getElementById("favicon");
      favicon.href = this.businessHome.favicon;
      document.title = this.businessHome.name;
      import(`./assets/scss/${this.businessHome.style}/all.scss`)
    }
    this.$loadScript('../../js/bootstrap.bundle.js')
      .then(res => {
        if(this.myWidth != 'sm' && !document.cookie.split('; ').find(row => row.startsWith('suggest'))) {
          this.showModal();
          document.cookie = 'suggest=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';      
        }
      })
    window.addEventListener('resize', this.handleResize);
    if(!cookies.isKey('agreed')) {
      this.$store.commit('showMsg', {
        condition: 3,
        close: true,
        text: 'This Site Uses Cookies !!!' || this.$t('AboutCookies'),
        desc: this.$t('WeUseCookiesToCollect')
        // type: 'warning'
      })
    }
    this.handleResize();
    this.$store.state.width == this.width;
    this.$store.dispatch('checkGuest')
      .then(res => {})
      .catch(err => {
        this.$store.dispatch('makeGuest')
          .then(res => {})
          .catch(err => console.error(err))
      });
    this.$store.dispatch('getAllCartItems');
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    profileRoute() {
      return this.$route.name == 'BusinessProfile';
    },
    locale() {
      return this.$i18n.locale;
    },
    currentPath() {
      return this.$route.name;
    },
    myWidth() {
      return this.$store.getters.width;
    },
    ar() {
      return this.$i18n.locale == 'ar';
    },
    cookiesModal() {
      return this.$store.getters.cookiesModal;
    },
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    },
  },
  methods: {
    localeSwitch(locale) {
      if(locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale}});
        this.$router.push(to.location); 
        this.$router.go(); 
      }
    },
    handleResize() {
      this.$store.state.width = window.innerWidth;
    },
        
    flag(lang) {
      return require('./assets/images/flags/' + lang + 'circle.png')
    },
    showModal() {
      $('#appmodal').modal('show')
    },
    search(text) {
      if(this.$route.name == 'SearchResults') {
        this.$router.go({ name: 'SearchResults', params: {
          text: text,
          cityId: this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          type: this.type
        }})
      }else {
        this.$router.push({ name: 'SearchResults', params: {
          text: text,
          cityId: this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          type: this.type
        }})
      }
    },
    searchVisible() {
        this.$store.state.searchForm  = !this.$store.state.searchForm;
    },
    profileRouteBtn() {
      if(this.profileRoute) {
        return this.$store.state.searchForm
      }else{
        return true;
      }
    }
  }
}
</script>
<style lang='scss'>
@import './assets/fonts/icons/css/import-icons.css';
// @import './assets/lib/bootstrap/css/bootstrap.min.css';
@import './assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
@import "./assets/css/swiper-bundle.css";
@import './assets/css/bootstrap.css';
@import './assets/all.scss';

// body{
//   background-color: #eeeeee;
// }
.langs-select{
  margin: 0 10px;
}
.navbar-inverse .navbar-nav>li>a {
  color: white;
}
.logo{
  margin: 8px 8px
}
.list-langs{
  cursor: default;
}
.user-lock::before{
  content: "\ea21";
}
.ma-4{
  margin: 20px;
  font-size: 20px;
  color: white;
  z-index: 100;
}

@media (max-width: 767px){
  header .logo{
    position: absolute;
    width: 60%;
    left: 80px;
  }
}
</style>


:class="Rtl ? ['rtl-body',  myWidth == 'sm' ? 'pb-12': ''] : myWidth == 'sm' ? 'pb-12': ''"

this.myWidth != 'sm' && !document.cookie.split('; ').find(row => row.startsWith('suggest'))