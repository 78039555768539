import Axios from 'axios';
import cookies from 'vue-cookies';

// Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Accept'] = 'application/json';

const order = {
  state: {
    product: {},
    shipMethods: [],
    payMethods: [],
    postCodes: [],
    myOrders: [],
    order: {},
    cartItems: [],
    canceled: [],
    // myAddress: {},
  },
  
  getters: {
    product: (state) => state.product,
    cartItems: (state) => state.cartItems,
    canceled: (state) => state.canceled,
    shipMethods: (state) => state.shipMethods,
    payMethods: (state) => state.payMethods,
    postCodes: (state) => state.postCodes,
    myOrders: (state) => state.myOrders,
    order: (state) => state.order,
    // myAddress: (state) => state.myAddress,
    
  },
  
  mutations: {
    setProduct(state, payload) {
      state.product = {...payload};
    },
    addToVCart(state, payload) {
      if (cookies.isKey('items')) {
        var items = JSON.parse(cookies.get('items'));
      }else {
        var items = [];
      }
      try {
        items.push(payload);
      } catch (error) {
        var items = [];
        items.push(payload);
      }
      cookies.set('items', JSON.stringify(items));
    },
    cartBusinessId(state, payload) {
      cookies.set('BusId', payload)
    },
    removeFromVCart(state, payload) {
      let items = JSON.parse(cookies.get('items'));
      let index = items.findIndex(el => el.product_id == payload);
      if(index == 0 && items.length == 1) {
        cookies.remove('items');
        cookies.remove('BusId');
      }else {
        items.splice(index, 1);
        cookies.set('items', JSON.stringify(items))
      }
    },
    destroyVCart() {
      cookies.remove('items');
      cookies.remove('BusId');
    },
    setMethods(state, payload) {
      state.shipMethods = [...payload.shipping_methods];
      state.payMethods = [...payload.payment_methods];
      if (payload.postal_codes != null) {
        state.postCodes = [...payload.postal_codes];
      } 
    },
    addHoldId(state, payload) {
      cookies.set('hold', payload.id);
      if(payload.postal_codes != null) {
        cookies.set('postal', JSON.stringify(payload.postal_codes))
      }
    },
    addOrderId(state, payload) {
      if (cookies.isKey('orders')) {
        var items = JSON.parse(cookies.get('orders'));
      }else {
        var items = [];
      }
      items.push(payload);
      cookies.set('orders', JSON.stringify(items));
    },
    removeOrderId(state, payload) {
      if (cookies.isKey('canceled')) {
        var items = JSON.parse(cookies.get('canceled'));
      }else {
        var items = [];
      }
      items.push(payload);
      state.canceled = [...items];
      cookies.set('canceled', JSON.stringify(items));
      
    },
    setMyOrders(state, payload) {
      if (payload.length > 0) {
        state.myOrders = [...payload];
      }
    },
    setOrder(state, payload) {
      state.order = {...payload}
    },
    setCartItems(state, payload) {
      if(payload && payload.length > 0) {
        state.cartItems = [...payload];
      }else {
        state.cartItems = [];
      }
    },
    setCanceled(state, payload) {
      if(payload && payload.length > 0) {
        state.canceled = [...payload];
      }
    },
    addAddress(state, payload) {
      cookies.set('addressId', payload.id);
      // cookies.set('addressObj', payload);
    },
    // setAddress(state, payload) {
    //   if(cookies.isKey('addressObj')){
    //     state.myAddress = cookies.get('addressObj');
    //   }else return 0
    // },

    
  },
  
  actions: {
    postCheckIn({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post('attends/checkin', {
          business_id: payload.buisness_id,
          source_id: payload.source_id,
          attenders: payload.attenders,
          // user_id: 22
        }).then(res => {
          if(res.data.success) {
            resolve(res);
          }
        }).catch(err => reject(err));
      })
    },
    postReservation({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post('buisness/booking/book', null,{
          params: {
            buisness_id: payload.buisness_id,
            source_id: payload.source_id,
            attenders: payload.attenders,
            // user_id: payload.user_id
          }
        }).then(res => {
          if(res.success) {
            resolve(res);
          }
        }).catch(err => reject(err));
      })
    },
    
    /// Product related Ordering 
    showProduct({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`showProduct`, {
          lang: payload.lang,
          product_id: payload.product_id
        }).then(res => {
          if (res.data.success == true) {
            commit('setProduct', res.data.data)
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    addToCart({commit}, payload) {
      let token = cookies.get('guest');
      let BusId = cookies.get('BusId');
      let products = JSON.stringify(payload.products)
      let price = JSON.stringify(payload.price)
      let quantity = JSON.stringify(payload.quantity)
      return new Promise((resolve, reject) => {
        Axios.post(`product_orders?api_token=${token}`,{
          lang: payload.lang,
          business_id: BusId,
          products: products,
          price: price,
          quantity: quantity,
        }, { }).then(res => {
          if (res.data.success == true) {
            commit('addHoldId', res.data.data)
            commit('setMethods', res.data.data);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    checkOutOrder({commit}, payload) {
      let payment = () => {
        if (payload.payment == 'cod') return 1; 
        if (payload.payment == 'paypal') return 2; 
        if (payload.payment == 'credit on delivery') return 3; 
      };
      let token = cookies.get('guest');
      let BusId = cookies.get('BusId');
      let hold = cookies.get('hold');
      let addressId = cookies.get('addressId');
      return new Promise((resolve, reject) => {
        Axios.post(`orders?api_token=${token}`, {
          amount: payload.total,
          tax: payload.tax,
          buisness_id: BusId,
          order_id: hold,
          payment_id: payment(),
          shipping_id: payload.shipping,
          delivery_cost: payload.deliveryCost,
          client_name: payload.name,
          client_phone: payload.phone,
          // postal_code: payload.postal_code,
          // street_name: payload.street,  
          // apartment_no: payload.houseNo,
          coupon_id: payload.coupon_code,
          hint: payload.comment,
          user_address_id: addressId,
          // coupon_id: ,
          
        }).then(res => {           
           if(res.data.success == true) {
            commit('addOrderId', cookies.get('hold'));
            resolve(res);
           }else reject(res);
       }).catch(err => reject(err))
      })
    },
    getMyOrders({commit}) {
      let token = cookies.get('guest');
      return new Promise((resolve, reject) => {
        if (cookies.isKey('orders') && JSON.parse(cookies.get('orders')).length > 0) {
          var items = JSON.parse(cookies.get('orders'));
        }else {
          reject('No Orders Found');
          return 0;
        }
        Axios.post(`myOrders?api_token=${token}`, {
          order_ids: items
        })
          .then(res => {
            if (res.data.success == true) {
              commit('setMyOrders', res.data.data);
              resolve(res);
            }else reject(res);
          }).catch(err => reject(err));
      })
    },
    getOrder({commit}, payload) {
      let token = cookies.get('guest');
        return new Promise((resolve, reject) => {
          Axios.post(`showOrder?api_token=${token}`, {
            order_id: payload,
          })
            .then(res => {
              if (res.data.success == true) {
                commit('setOrder', res.data.data);
                resolve(res);
              }else reject(res);
            }).catch(err => reject(err));
        })
    },
    cancelOrder({commit}, payload) {
      let token = cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`orders/cancel/${payload}?api_token=${token}`)
        .then(res => {
          if (res.data.success == true) {
            commit('removeOrderId', payload);
            resolve(res);
          }else reject(res);
        }).catch(err => reject(err));
      })
    },
    getAllCanceled({commit}) {
      commit('setCanceled', JSON.parse(cookies.get('canceled')))
    },
    getAllCartItems({commit}) {
      commit('setCartItems', JSON.parse(cookies.get('items')))
    },
    checkPhone({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`validate/phone/${payload}`)
        .then(res => {
          if (res.data.success == true) {
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err));
      })
    },
    createAddress({commit}, payload) {
      let token = cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`addAddress?api_token=${token}`, {
          postal_code: payload.postal_code,
          apartment_no: payload.apartment_no,
          street_name: payload.street_name,
          address: payload.address,
          phone: payload.phone,
        }).then(res => {
          if (res.data.success == true) {
            commit('addAddress', res.data.data);
          }else reject(res)
        }).catch(err => reject(err))
      })
    },
    updateAddress({commit}, payload) {
      let token = cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`updateAddress?api_token=${token}`, {
          postal_code: payload.postal_code,
          apartment_no: payload.apartment_no,
          street_name: payload.street_name,
          address: payload.address,
          address_id: payload.address_id,
          phone: payload.phone,
        }).then(res => {
          if (res.data.success == true) {
            commit('addAddress', res.data.data);
            resolve(res);
          }else reject(res)
        }).catch(err => reject(err))
      })
      
    },
    updatePayment({commit}, payload) {
      console.log('updatePayment', payload)
        let token = cookies.get('guest');
        return new Promise((resolve, reject) => {
          Axios.post(`orders/update?api_token=${token}`, {
            order_id: payload.order_id,
            payment_id: payload.payment_id,
            secret_word: payload.secret_word,
            status: payload.status,
            transaction_id: payload.transaction_id,
            fees: payload.fees,
            _method: "PATCH",
          }).then(res => {
            if (res.data.success == true) {
              resolve(res)
            }else reject(res);
          }).catch(err => reject(err))
      })
    },
    validateCoupon({commit}, payload) {
      let token = cookies.get('guest');
      return new Promise((resolve, reject) => {
        Axios.post(`validateCoupon?api_token=${token}`, {
          business_id: payload.business_id,
          coupon_code: payload.coupon_code
        }).then(res => {
          if (res.data.success == true) {
            resolve(res)
          }else reject(res);
        }).catch(err => reject(err))
      })
    }
  }
}

export default order;