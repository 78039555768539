<template>
  <div  
    class="row pointer"
    :class="[(large ? 'pop-card-lg' : 'pop-card'), (ar ? 'flex-row-reverse' : 'flex-row')] " 
    @click="openModal(item.id) "
  >
    <div
      :class="large ? ['col-12', 'col-sm-5']: ['col-12', 'col-sm-5']"
    >
      <img
        :class=" (item.image) || (item.photo)  ? '' : 'fit-img-none'" 
        :src="placeHolderImg" />
    </div>
    <div 
      :class="large ? ['col-12', 'col-sm-7'] : ['col-12', 'col-sm-7']"
    >
      <div class="row">
        <div 
          class="pop-head m-3 col-12 text-ellipsis"
          :class="ar ? 'text-right' : 'text-left'"  
          :title='item.name'
        >
          {{item.name}}
        </div>
        <!-- <span class="">
          <div 
            class='btn-icon-trans'
            :class="ar ? 'f-left' : 'f-right'"
          >
            <i class="fa fa-heart-o"></i>
          </div>
        </span> -->
      </div>
      <div 
        class="row"
        :class="ar ? 'text-right' : 'text-left'" 
        v-if="show == 'Yes'"
      >
        <div 
          class="mt-1 col-12 "
          :class="large ? 'opt-label' : ['data-sub', 'text-center']" 
        >
          <span class="m-3 ">
             {{item.price_after_sale > 0 ? item.price_after_sale : item.price}} €
          </span>
          <span class="old-price m-3" v-if='large && item.price_after_sale > 0 '>
            {{item.price}} € 
          </span>
        </div>
        <div class="col-12">
          <button class="btn-pri mt-4" @click="openModal(item.id)" v-if="large">
            <i class="fa fa-info-circle"></i>
            {{$t('details')}}
          </button>
        </div>
      </div>

    </div>
    
  </div>
</template>

<script>
import Stars from '@/components/misc/Stars';
import { mapActions } from 'vuex';
export default {
  created() {
    this.imageSrc(this.item)
  },
  components: {
    Stars
  },
  data() {
    return {
      placeHolderImg:  require('../../assets/images/logo-icon.png')
    }
  },
  props: ['item', 'openModal', 'show', 'large'],
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    },
  },
  methods: {
    testImage(URL) {
      return new Promise((resolve, reject) => {
        var tester=new Image();
        tester.src=URL;
        tester.onload= () => {
          resolve(URL);
        };
        tester.onerror= () => {
          reject();
        };
      })
    },
    imageSrc(item) {
      if (item.image) {
        this.testImage(item.image)
          .then(res => {
            this.placeHolderImg = item.image;
          }).catch(err => false)
      }else if (item.photo) {
        this.testImage(item.photo.thumb)
          .then(res => {
            this.placeHolderImg = item.photo.thumb;
          }).catch(err => false)
      }else return false;
    }
  }
}

</script>