<template>
  <div class="container-fluid">
    <div class="download-contain" v-if='width == "sm"'>
      <div class="opt-label text-ellipsis" :class="t_ar">
        {{$t('downloadApp')}}
      </div>
      <div class="">
        <div class=" store-icons row d-flex justify-content-around">
          <i class="fa fa-android pointer" @click='goStore'></i>
          <i class="fa fa-apple pointer " @click='goApple'></i>
          <span class="appgallery pointer" @click='goGallery'>
            <img src='../../assets/images/logo/appgallery.png'/>
          </span>
        </div>
      </div>
    </div>
    <div class="row my-auto p-4" :class="flex_ar_reverse" v-else>
      <div class="col-12 col-xl-9" >
        <div class="row  my-5 pb-3" :class="t_ar">
          <div class="col-12 download-header pr-5" >
              {{$t('downloadApp')}} 
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-12 download-subheader pr-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu. Nam at libero quis dui pellentesque efficitur 
          </div>
        </div> -->
        <div class="row mt-5">
          <div class="col-12">
            <div class="row" :class="flex_ar_reverse">
              <div class="col-12 col-lg-4 my-1">
                <div class="row google-play pointer" :class="flex_ar_reverse" @click="goStore">
                  <div class="store-image" :class="pr_ar">
                    <img  src="../../assets/images/logo/gp.png"/> 
                  </div>
                  <div class="" :class="t_ar">
                    <div class="smaller-get">{{$t('GetItOn')}}</div>
                    <div class="store-name">{{$t('GooglePlay')}}</div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-4 my-1" >
                <div class=" row apple-store pointer" :class="flex_ar_reverse" @click="goApple">
                  <div class="store-image" :class="pr_ar">
                    <img  src="../../assets/images/logo/appStore.png"/> 
                  </div>
                  <div :class="t_ar">
                    <div class="smaller-get">{{$t('GetItOn')}}</div>
                    <div class="store-name">{{$t('AppStore')}}</div>
                  </div>
                </div>
              </div>
              
              <div class="col-12 col-lg-4 my-1" >
                <div class="row huawei-gallery pointer" :class="flex_ar_reverse" @click="goGallery">
                  <div class="store-image" :class="pr_ar">
                    <img  src="../../assets/images/logo/appgallery.png"/> 
                  </div>
                  <div :class="t_ar">
                    <div class="smaller-get">{{$t('ExploreItOn')}}</div>
                    <div class="store-name">{{$t('AppGallery')}}</div>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
        </div>
      </div>
        <div class="col-12 col-xl-3 d-none d-lg-block" v-if='width == "lg"'>
          <!-- <div class="smartphone">
            <div class="">
              <img height="420px" width="220px" src='../../assets/images/Mask.png'  >
            </div>
          </div> -->
          <div class="phone-holder">
            <img class="phone" height='430px' width='220px' src='../../assets/images/iphoneX.png'/>
            <img v-if="!businessHome" class="phone-screen" height='400px' width='191px' src='../../assets/images/Mask.png'/>
            <div v-else>
              <img class="phone-screen-business" height='120px' width='120px' :src='businessHome.logo'/>
              <span class="opt-name phone-screen-name">{{businessHome.name}}</span>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {pluginOptions} from "/vue.config"
export default {

  methods: {
    goStore() {
      window.open(this.businessHome ? this.businessHome.googlePlay : 'https://play.google.com/store/apps/details?id=com.snawnawapp', '_blank')
    },
    goApple() {
      window.open(this.businessHome ? this.businessHome.ios : 'https://apps.apple.com/us/app/id1530699560', '_blank')
    },
    goGallery() {
      window.open(this.businessHome ? this.businessHome.huawei : 'https://appgallery.huawei.com/#/app/C103728625', '_blank')
    }
  },
  computed: {
    width() {
      return this.$store.getters.width;
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row': 'flex-row-reverse';
    },
    flex_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': 'flex-row';
    },
    pr_ar() {
      return this.$i18n.locale == 'ar' ? 'pl-3': 'pr-3';
    },
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    },
  }
}

</script>