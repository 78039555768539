<template>
 <section class="container-fluid">
    <div class="row">
      <div class="col-12">
         <div 
          class="d-flex justify-content-between mb-4"
          :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'"
        >
          <div class="align-self-end">
            <button class="btn-icon-black" @click='swiper.slidePrev()' v-if='width != "sm"'>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="btn-icon-black" @click='swiper.slideNext()' v-if='width != "sm"'>
              <i class="fa fa-chevron-right"></i>
            </button>
            </div>
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="">
            <div class="header-home">
              {{$t('orderNow')}}
            </div>
            <div class="subheader-home">
              {{$t('subheader3')}}
            </div>
          </div>
        </div>
        </div>
        <div class="text-center col-12">
         <div class="row">
            <div class="col">
               <NoPlaces v-if='!exist' :msg="$t('noPlaces')"/>

              <Swiper ref='orderNowSwiper' v-else class="swiper" :options='swiperOptions' :dir="$i18n.locale == 'ar' ? 'rtl': ''">
                <SwiperSlide class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-4" v-for='item in orderNow' :key='item.id'>
                  <Highlited  :item='item'/>
                </SwiperSlide>        
              </Swiper>
            </div>
         </div>

        </div>
    </div>
  </section>
</template>

<script>
import NoPlaces from '@/components/misc/NoPlaces.vue';
import Highlited from '@/components/Listings/Highlited.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import { helpers } from '../Helpers';

export default {
  name: 'OrderNow',
  
  components: {
    NoPlaces,
    Highlited,
    Swiper, 
    SwiperSlide
  },

  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
    }
  },

  mixins: [helpers],
  props:['orderNow', 'exist'],   
  computed: {
    swiper() {
      return this.$refs.orderNowSwiper.$swiper;
    },
    width() {
      return this.$store.getters.width;
    },
  },
  methods: {
  }
}
</script>

<style scoped>
.trending:after{
  background-image: unset;
}
</style>