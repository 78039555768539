<template>
  <section class="container-fluid mt-8">
    <div class="row">
      <div class="col-12">
         <div 
          class="d-flex justify-content-between mt-4 mb-4"
          :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'"
        >
          <div class="align-self-end" >
            <button class="btn-icon-black" @click='swiper.slidePrev()' v-if='width != "sm"'>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="btn-icon-black" @click='swiper.slideNext()' v-if='width != "sm"'>
              <i class="fa fa-chevron-right"></i>
            </button>
            </div>
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="">
            <div class="header-home">
              {{$t('categories')}}
            </div>
            <div class="subheader-home">
              {{$t('line3')}}
            </div>
          </div>
        </div>
        </div>
        <div class="text-center col-12">
         <div class="row">
            <div class="col">
              <Swiper class="swiper" ref="mySwiper" :options="swiperOptions" :dir="$i18n.locale == 'ar' ? 'rtl': ''">
                <SwiperSlide class="col-auto " v-for="cat in categories" :key='cat.id'> 
                  <div >
                    <CategoryCard :category='cat'/>
                  </div>
                </SwiperSlide>
            </Swiper>
            </div>
         </div>

        </div>
    </div>
  </section>
</template>

<script>
import CategoryCard from '@/components/Listings/CategoryCard.vue';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper';

export default {
  props: ['categories'],
  components: {
    CategoryCard,
    Swiper,
    SwiperSlide,
  } ,
  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 20,
      },
      
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    width() {
      return this.$store.getters.width
    }
  }
}
</script>
