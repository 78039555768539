
import Home from '../views/Home.vue';

export default [
    {
      path: '',
      name: 'Home',
      component: Home
    },
    {
      path: 'about',
      name: 'About',
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: 'privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
    },
    {
      path: 'search-results',
      name: 'SearchResults',
      component: () => import(/* webpackChunkName: "listing" */ '../views/SearchResults.vue')
    },
    // {
    //   path: 'products',
    //   name: 'AllProducts',
    //   component: () => import(/* webpackChunkName: "listing" */ '../views/SearchResults.vue')
    // },
    // {
    //   path: 'cities',
    //   name: 'AllCities',
    //   component: () => import(/* webpackChunkName: "listing" */ '../views/SearchResults.vue')
    // },
    {
      path: 'business/:id',
      name: 'BusinessProfile',
      component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue')
    },
    {
      path: 'product/:id',
      name: 'Product',
      component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue')
    },
    //access business profile
    {
      path: ':id(\\d+)',
      name: 'Business',
      component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reserve.vue'),
      meta: {
        requireAuth: true,
      },
    },
    {
      path: 'reservations',
      name: 'Reservations',
      component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reserve.vue'),
      meta: {
        requireAuth: true,
      },
    },
    {
      path: 'order',
      name: 'Order',
      component: () => import(/* webpackChunkName: "forms" */ '../views/OrderLayout.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: 'reservation/:id?',
          name: 'Reservation',
          // component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reservation.vue'),
          //
          //
          //
          //Here Is The Other COmponent
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/Reserve.vue'),
        }, 
        {
          path: 'cart',
          name: 'Cart',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/Cart.vue'),
        },
        {
          path: 'my-orders',
          name: 'MyOrders',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/MyOrders.vue'),
        },
        {
          path: 'checkout',
          name: 'CheckOut',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/CheckOut.vue'),
        },
        {
          path: 'payment',
          name: 'Payment',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/Payment.vue'),
        },
      ]
    },
    {
      path: 'auth',
      name: 'Auth',
      component: () => import(/* webpackChunkName: "forms" */ '../views/FormsLayout.vue'),
      children: [
        {
          path: 'forgot-password',
          name: 'Forgot',
          component: () => import(/* webpackChunkName: "forms" */ '../components/forms/ForgotPassword.vue'),
        },
        {
          path: 'login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "forms" */ '../components/forms/UserLogin.vue'),
        },
        {
          path: 'register',
          name: 'Register',
          component: () => import(/* webpackChunkName: "forms" */ '../components/forms/Register.vue'),
        },
        {
          path: 'review',
          name: 'Review',
          component: () => import(/* webpackChunkName: "forms" */ '../components/forms/WriteReview.vue'),
        }
      ]
    },
    

  ]