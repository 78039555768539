import Home from '../views/Home.vue';

export default [
    {
      path: '',
      name: 'Home',
      component: Home
    },
    {
      path: 'business',
      name: 'BusinessProfile',
      component: () => import(/* webpackChunkName: "profile" */ '../views/BusinessProfile.vue')
    },
    
    {
      path: 'about',
      name: 'About',
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: 'privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
    },
    {
      path: 'order',
      name: 'Order',
      component: () => import(/* webpackChunkName: "forms" */ '../views/OrderLayout.vue'),
      meta: {
        requireAuth: true,
      },
      children: [
       
        {
          path: 'cart',
          name: 'Cart',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/Cart.vue'),
        },
        {
          path: 'my-orders',
          name: 'MyOrders',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/MyOrders.vue'),
        },
        {
          path: 'checkout',
          name: 'CheckOut',
          component: () => import(/* webpackChunkName: "forms" */ '../components/order/CheckOut.vue'),
        },
      ]
    },
  ]