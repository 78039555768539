import Axios from 'axios';

const search = {
  state: {
    searchResults: [],
    cities: [],
    categories: [],
    searched: false,
  },

  getters: {
    searchResults: (state) => state.searchResults,
    cities: (state) => state.cities,
    categories: (state) => state.categories,
  },

  mutations: {
    setSearchResults(state, payload) {
      state.searchResults = {...payload};
    },
    setAllCategories(state, payload) {
      state.categories = [...payload];
    },
    setAllCities(state, payload) {
      state.cities = [...payload];
    },
  },  
  
  actions: {
    getSearchResults({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`searchBusinesses`, null, {
          params: {
            lang: payload.locale,
            text: payload.text,
            category_id: payload.categoryId,
            city_id: payload.cityId,
            page: payload.page,
            paginate: 10,
            booking_only: payload.booking,
            cities: true,
            categories: true,
          }
        }).then(res => {
          commit('setSearchResults', res.data.data);
          commit('setAllCategories', res.data.categories);
          commit('setAllCities', res.data.cities);
          resolve(res);
        }).catch(err => {
          console.error(err);
          reject(err);
        });
      })
    },
    // getAllCategories({commit}, payload) {
    //   Axios.get(`businessCategories`, {
    //     params: {
    //       lang: payload
    //     }
    //   }).then(res => {
    //       commit('setAllCategories', res.data.data)
    //     }).catch(err => console.log(err))
    // },
    // getAllCities({commit}, payload) {
    //   Axios.get(`cities`, {
    //     params: {
    //       lang: payload
    //     }
    //   }).then(res => {
    //     commit('setAllCities', res.data.data)
    //   }).catch(err => console.log(err))
    // }
  }, 


}

export default search;