<template>
  <!-- <div class="container-fluid blog blog-home">
    <div class="row row-title">
      <h1>{{$t('events')}}</h1>
      <h4>{{$t('subheader4')}}</h4>
    </div>
    <NoPlaces v-if='!exist' />
    <div 
      v-else 
      class="row listing white"
    >

      <div 
        class="col-sm-3"
        v-for="event in events" :key='event.id'
      >
        <div class="listing-item">
          <a href="#" class="category-icon icon-news"></a>
          <div class="listing-item-date">
            <div class="day">{{event.date.split('-')[2]}}</div>
            <div class="month">{{monthText(event.date.split('-')[2]).abbr}}</div>
          </div>
          <a href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                {{location}}
              </div>
            </div>
            <img alt="" src="../assets/images/blog/14.jpg">
          <div class="listing-black-overlay"></div></a>
          
        </div>
      </div>
      

    </div>
  </div> -->
  <div class="container">
    <Event />
  </div>
</template>

<script>
import NoPlaces from '@/components/misc/NoPlaces.vue';
import Event from '@/components/Listings/Event';
import { helpers } from '../Helpers'
export default {
  name: 'Events',
  mixins: [helpers],
  components:{
    NoPlaces
  },

  props: ['events', 'exist']
}
</script>

<style scoped>
.row{
  margin-left: 0;
}
.row-title h1:before{
  content: "\ea8a";
  font-family: 'icon';
}
</style>