<template>
  <div class="container-fluid">
    <div v-if='product.media && !modalLoading' class="row pro-details">
      <div class="col-12">
         <div class="row mainBody-card">
           <div class="col-12 col-md-12 col-lg-5 col-xl-4">
              <!-- {{options}} -->
              <div class="image">
                <img :src="product.media && product.media.length > 0 ? product.media[0].url : product.business.media[0].url" />
              </div>
            </div>

            <div class="pro-header mt-4 col-sm-12  col-lg-7 col-xl-8" :class="t_ar">
              {{product.name}}
              <div class="subheader-home mt-3" :class="t_ar" v-html='product.description'></div>


              <div class="form-group mt-4" v-if='show == "Yes"'>
                  <!-- <label class="input-label">{{$t('quantity')}}:</label> -->
                  <button class="btn-icon-def p-0"  @click='quantity ++'><i class="fa fa-plus"></i></button>
                  <input type='number' v-model='quantity' class="w-25 text-input-sm mx-3" placeholder="How Many ?"/>
                  <button class="btn-icon-def p-0" @click='decreaseQuantity'><i class="fa fa-minus" ></i></button>
                </div>
            </div>  
         </div>
      </div>


      <div class="col-12 pro-options my-md-3 px-md-5" :class="$i18n.locale == 'ar' ? 'pr-4' : 'pl-3'" v-if='show == "Yes"'>
        <div class="row" v-if="product.options && product.options.length > 0 && product.options[0].option_values.length > 1"> 
        <div 
            v-for="opt in product.options" :key='opt.id' 
            class="pro-options-contain col-12"
          >
            <div class="opt-name my-4 row" :class="t_ar" v-if="product.options.length > 0">
              <span class="col-12" :class="$i18n.locale == 'ar' ? 'mx-4' : 'mx-n4'">{{opt.name}}</span>
            </div>
            <div class="opt-options  mt-2 row " v-if="opt.type == 'radio' && opt.option_values.length > 0">
              <div class="custom-radio custom-control col-12 d-flex justify-content-between" :class="flex_ar_reverse"   v-for='val in opt.option_values' :key='val.id'>
                <div class="d-flex" :class="flex_ar_reverse">
                  <input 
                  class="custom-control-input mx-n3 my-2"
                  type='radio' 
                  :id='val.label'
                  :value="{ id: val.id , label:val.label, price: val.price_after_sale ? val.price_after_sale : val.price }" 
                  v-model='options[opt.name]' 
                />
                <label class="opt-label custom-control-label mx-2" :class="$i18n.locale == 'ar' ? 'custom-control-label-ar' : ''" :for='val.label'>
                  {{val.label}} 
                </label>
                </div>
                <span class="opt-price " > € {{val.price_after_sale ? val.price_after_sale : val.price}}</span>
              </div>
            </div>
            <div class="opt-options row"  v-else-if="opt.type == 'checkbox' && opt.option_values.length > 0">
              <div class="custom-checkbox custom-control col-12 d-flex justify-content-between" :class="flex_ar_reverse" v-for='val in opt.option_values' :key='val.id'>
                <div class="d-flex" :class="flex_ar_reverse">
                  <input 
                    class="custom-control-input mx-n3 my-2"
                    :class="f_ar"
                    type='checkbox' 
                    :id='val.label'
                    :true-value="{id: val.id, price: val.price_after_sale ? val.price_after_sale : val.price}"
                    false-value=null
                    v-model='options[val.label]' 
                  />
                  <label class="opt-label custom-control-label mx-2" :class="$i18n.locale == 'ar' ? 'custom-control-label-ar' : ''" :for='val.label'>
                    {{val.label}} 
                  </label>
                </div>
                <span class="opt-price"> € {{val.price_after_sale ? val.price_after_sale : val.price}}</span>
              </div>
            </div>
          </div>  
        </div> 
        <div v-else class="pro-options-price col-12" :class="t_ar">
          {{$t('price')}}: 
          <span class="old-price mx-2" v-if='product.price_after_sale > 0 '>
          € {{product.price}}
          </span>
          <span class="mx-2">
            € {{product.price_after_sale > 0 ? product.price_after_sale : product.price}}
          </span>
        </div>
        <!-- <div class="main-card mt-4 pa-2 col-12">
          <div class="form-group">
            <label class="input-label">{{$t('specialRequest')}}</label>
            <input type='text' v-model='request' class="text-input mt-2 form-control"  :placeholder="$t('specialRequest')"/>
          </div>
          <div class="form-group">
            <label class="input-label">{{$t('quantity')}}:</label>
            <input type='number' v-model='quantity' class="text-input mt-2 form-control" placeholder="How Many ?"/>
          </div>
          
        </div> -->
      </div>
      <NoPlaces class="mt-4 row" v-else :msg="$t('pricesBlocked')" /> 
      
        <!-- <button type="button" class=" btn-def" @click='closeModal'>{{$t('close')}}</button> -->
        <button type="button" class=" btn-pri col-12" @click='addtoCart' v-if="show == 'Yes'">
          <i class="fa fa-shopping-cart"></i>
          {{$t('addToCart')}}
        </button>
    
    </div>
    <NoPlaces v-else class="my-4 row"/> 
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import NoPlaces from '@/components/misc/NoPlaces';
import cookies from 'vue-cookies';

export default {
  components: {
    NoPlaces
  },
  props: ['show'],
  data() {
    return {
      quantity: 1,
      options: {},
      request: '',
    }
  },
  mounted() {
    const vm = this;
    $('.modal').on('hide.bs.modal', function(e) {
      vm.options = {};
      vm.quantity = 1;
    })
  },
  computed: {
    ...mapGetters(['product', 'loading', 'modalLoading']),
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row': 'flex-row-reverse';
    },
    flex_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': 'flex-row';
    },
  },
  methods: {
    ...mapMutations(['addToVCart', 'cartBusinessId', 'showMsg']),
    ...mapActions(['getAllCartItems']),
    closeModal() {
      $('.modal').modal('hide');
    },
    addtoCart() {
      if(cookies.isKey('BusId')) {
        if (this.$route.params.id == cookies.get('BusId')) {
          let item = this.buildItem();
          this.addToVCart(item);
          this.getAllCartItems();
          this.showMsg({
            close: false,
            text: 'Added to Cart Successfully',
            type: 'success'
          });
          $('.modal').modal('hide');
        }else {
          let item = this.buildItem();
          this.showMsg({
            close: true,
            text: 'Your Cart Items Will be Cleared',
            type: 'error',
            condition: 4,
            business_id: this.$route.params.id,
            item,
          }); 
        }
      }else {
        this.cartBusinessId(this.$route.params.id);
        this.addtoCart()
      }
      let item = {};
    },
    buildItem() {
      if (Object.entries(this.options).length > 0) {
        
        var item = {
          name: this.product.name,
          product_id: this.product.id,
          options: this.options,
          quantity: this.quantity,
          tax: this.product.tax != null ? this.product.tax : 0,
        }
      }else {
        
        var item = {
          name: this.product.name,
          product_id: this.product.id,
          price: this.product.price_after_sale > 0 ? this.product.price_after_sale : this.product.price ,
          quantity: this.quantity,
          tax: this.product.tax != null ? this.product.tax : 0,
        }
      }
      return item
    },
    decreaseQuantity() {
      if(this.quantity -1 != 0 && this.quantity != 1) {
        this.quantity --
      }
    }
  }
}
</script>


