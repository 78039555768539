import Axios from 'axios';

const auth = {
  state: {

  },

  getters: {
    loggedIn: () => true,
  },

  mutations: {

  },

  actions: {
    userLogin() {
      return new Promise((resolve, reject) => {
        Axios.post()
      })
    },
    userRgister() {
      return new Promise((resolve, reject) => {
        Axios.post()
      })
    },
    forgetPassword() {
      return new Promise((resolve, reject) => {
        Axios.post()
      })
    },
    socialLogin() {
      return new Promise((resolve, reject) => {
        Axios.post()
      })
    },
  },


}

export default auth;