<template>
  <div class='mt-3'
    :class="width != 'sm' ? 'mx-9' : ''"
  >
      <div v-if="!businessHome">
        <Banner :getInspired='getInspired'/>
        <Categories :categories='getInspired'/>
        <!-- <Events :events='events' :exist='events.length > 0'/> -->
        <AroundMe :aroundMe='aroundMe' :exist='aroundMe.length > 0'/>
        <HotDeals :hotDeals='hotDeals' :exist='hotDeals.length > 0'/>
        <OrderNow :orderNow='orderNow' :exist='orderNow.length > 0' />

      </div>
      <BusinessProfile v-else />
      
    </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue';
import Categories from '@/components/Categories';
import AroundMe from '@/components/AroundMe.vue';
import OrderNow from '@/components/OrderNow.vue';
import HotDeals from '@/components/HotDeals.vue';
import Events from '@/components/Events.vue';
import { mapGetters, mapActions } from 'vuex';
import {pluginOptions} from "/vue.config"
import BusinessProfile from "./BusinessProfile"

export default {
  name: 'Home',
  components: {
    Banner,
    Categories,
    AroundMe,
    OrderNow,
    HotDeals,
    BusinessProfile,
    Events
  },

  watch: {
    locale: function(newLocale) {
      this.getHomeData({
        locale: newLocale,
        latitude: this.latitude,
        longitude: this.longitude
      })
        .then()
        .catch(err => console.error(err));
    },

    longitude: function(newPos) {
      this.getHomeData({
        locale: this.$i18n.locale,
        latitude: this.latitude,
        longitude: this.longitude
      })
        .then()
        .catch(err => console.error(err));
    },
  },
  
  mounted() {
    if(!this.businessHome)
    {
      this.$loadScript('../../../js/bootstrap.bundle.js');
      this.getHomeData({
        locale: this.$i18n.locale,
        latitude: this.latitude,
        longitude: this.longitude
      })
        .then()
        .catch(err => console.error(err));
    }
    this.getLocation();
  },

  data() {
    return {
      longitude: '',
      latitude: '',
    }
  },

  computed: {
    ...mapGetters([
        'aroundMe',
        'events',
        'getInspired',
        'orderNow',
        'events',
        'hotDeals',
        'width',

    ]),
    locale() {
      return this.$i18n.locale
    },
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    }
  },

  methods: {
    ...mapActions([
      'getHomeData', //also supports payload `this.nameOfAction(amount)` 
    ]),

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.success);
      } else { 
        console.log('error')
      }
    },

    success(position) {
      this.longitude = position.coords.longitude;
      this.latitude = position.coords.latitde;
    },
  }
}
</script>
