<template>
  <nav class="navbar navbar-light footer-div">
    <router-link
          :to="{ name: 'Home', params: { locale: $i18n.locale }}"
        >
        <a class="footer-brand" >
          <img v-if="!businessHome" height='36px' src='../assets/images/logo/logo3.png' />
          <div v-else>
              <img :src="businessHome.logo" style="width: 50px;vertical-align: middle;" />
              <span class="business-footer-name pl-3 ">{{businessHome.name}}</span>
          </div>
        </a>
    </router-link>

    <ul class="nav navbar-nav d-flex flex-row" :class="locale == 'ar' ? 'f-left' : 'f-right'">
        <!-- <li class="active"><a href="#">Home.</a></li> -->
        <li v-if='!businessHome'>
          <router-link
            :to="{ name: 'Reservation', params: { locale: $i18n.locale }}"
          >
              <a class="pointer">{{$t('Reservations')}}</a>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'Privacy', params: { locale: $i18n.locale }}"
          >
              <a class="pointer">{{$t('privacy')}}  </a>
          </router-link>
        </li>
      </ul>
  </nav>
</template>

<script>
import {pluginOptions} from "/vue.config"
export default {
  name: 'AppFooter',
  data() {
    return {
      locales: this.$i18n.availableLocales,
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    },
  },

  methods: {
    localeSwitch(locale) {
      if(locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale}});
        this.$router.push(to.location); 
      }
    },
    flag(lang) {
      return require('../assets/images/flags/' + lang + 'circle.png')
    },
  }

}
</script>