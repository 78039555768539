<template>
  <div class="mouse container-fluid">
    
      <div class="" v-if='business.media && !profileLoading && !businessHost'>
          <div class=" profile-header " :style="business.media && business.media[1] ? 'background: url('+business.media[1].url+');background-size: 100% auto;min-height: 300px' : null">

            <div class=" row" :class="{' justify-content-center': !business.media || !business.media[1]}" v-if="!businessHost">
              <div class="col-md-3" :class="!business.media || !business.media[1] ? 'text-right' : 'text-left pl-5'">
                <div class="profile-image my-3 text-center">
                  <img :src="business.media ? business.media[0].url  : 'https://images.squarespace-cdn.com/content/v1/5387997be4b0bd6d1373cbff/1610119502963-EYNJP5CB5847764TVFRR/ke17ZwdGBToddI8pDm48kDAv91l_EVpAtt3T82Wm1m9Zw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVGRdKQdAvYPFeoNHcl0B9NbYhRtIalXltUl4wqyICqU51hQUjdct07RUVzezzWpvVo/GraySquare.jpg' " />
                    <div class='header-profile' v-if="business.media && business.media[1]">
                      {{business.name}}
                      <span class="pointer rating">{{business.rate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-5 text-left" v-if="!business.media || !business.media[1]">
                <div class='header-profile my-4'>
                  {{business.name}}
                  <span class="pointer rating">{{business.rate}}</span>
                </div>
                <div class="header-profile-cats pointer" v-html='business.category.name' @click="goCat(business.category.id)"></div>
                
              </div>
            </div>
            
          </div>
      </div>
      <div class="row mt-4" v-else-if="businessHost">
          <div class="col-12">
              <div class="row justify-content-center my-4 mb-5">
                <div class="col-1 align-self-center" v-if="width != 'sm'">
                  <button class="btn-icon-black pointer" @click='swiper.slidePrev()'>
                    <i class="fa fa-chevron-left"></i>
                  </button>
                </div>
                <div class="col-10">
                  <Swiper class="swiper" ref='categoriesSlider' :options='swiperOptions'>
                    <SwiperSlide v-for="(cat, index) in productCategories" :key='index'>
                      <div class='pointer cat-badge' :class="outerCat.id == cat.id && openedTab == 'rel' ? 'active' : ''" @click='chooseCat(cat.id, cat.name)'>
                        <span class="cat-badge-text text-ellipsis" >{{cat.name}}</span>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div class="col-1 align-self-center" v-if="width != 'sm'">
                  <button class="btn-icon-black pointer" @click='swiper.slideNext()'>
                    <i class="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
          </div>
          <div class="col-12">
            <div class="row ">
              <!-- <div class="home-icons col-12 col-md-1 d-none d-md-block" >
                <div class="home-icons mx-auto mx-xl-3 d-flex flex-column pointer">
                  <div class="my-3 " @click='goApple'>
                    <i class="fa fa-apple"></i>
                  </div>
                  <div class="my-3" @click='goAndroid'>
                    <i class="fa fa-android"></i>
                  </div>
                  <div class="my-3" @click='goGallery'>
                    <img src='../assets/images/logo/appgallery.png'/>
                  </div>
                  <div class="my-3" @click='goFacebook'>
                    <i class="fa fa-facebook"></i>
                  </div>
                </div>
              </div> -->
              <div class="col-12" v-if='slides && slides.length > 0'>
                <div id="homeCarousel" class="carousel slide"  data-ride="carousel">
                  <ol class="carousel-indicators" v-for='(slide, index) in slides' :key='index'>
                    <li data-target="#homeCarousel" :data-slide-to="index" class="active"></li>
                  </ol>
                  <div class="carousel-inner my-carousel" style="height: 100%" v-for='(slide, index) in slides' :key='(index+1)*100'>
                    <div class="carousel-item pointer" :class="index == 0 ? 'active' : ''"  @click="goLink(slide.value)">
                      <img :src="slide.url" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <NoPlaces class="mt-5" v-else />
      <ul 
        class='nav tabs justify-content-center ' 
        :class="[$i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row', businessHost ? ['businessTabs', 'mt-n2'] : ''] " 
        v-if='business.media && !profileLoading'
      >
          <li 
            class='nav-item tabs-link' 
            :class="openedTab == 'desc' ? 'active' : ''"
            @click="changeTab('desc')"
            role='tab'
          >
            {{$t('info')}}
          </li>
          <li 
            class='nav-item tabs-link' 
            @click="changeTab('rev')" 
            :class="openedTab == 'rev' ? 'active' : ''"
            role='tab'
          >
              {{$t('reviews')}}
            </li>
          <!-- <span class='info-sec tabs-link' >categories</span> -->
          <li 
            class='nav-item tabs-link' 
            @click="changeTab('rel')" 
            v-if="business.categories.length > 0"
            :class="openedTab == 'rel' ? 'active' : ''"
            role='tab'
          >
            {{$t('menu')}}
          </li>
          <li 
            class='nav-item tabs-link' 
            @click="goReserve" 
            v-if="business.allow_booking || business.allow_checkin"
            role='tab'
          >
            {{$t('Reserve')}}
          </li>
      </ul>
      <div 
        class="mt-5" 
        :class="[width != 'sm' ? 'mx-9' : '' , businessHost ? 'mt-12' : '']"
      >
        <Profile id='desc' v-if="openedTab == 'desc' && business.media && !profileLoading" :changeTab='changeTab' :business='business' :hotDeals='busHotDeals'/>
        <Reviews id='rev' v-if="openedTab == 'rev'" :business='business'/>
        <BusinessProducts id='rel' v-if="openedTab == 'rel'" :business='business'/>
      </div>
    </div>
</template>

<script>
import Profile from '@/components/Listings/Profile.vue';
import Amenities from '@/components/Listings/Amenities.vue';
import Reviews from '@/components/Listings/Reviews.vue';
import ProductsList from '@/components/Listings/ProductsList.vue';
import BusinessProducts from '@/components/Listings/BusinessProducts.vue';
import NoPlaces from '@/components/misc/NoPlaces.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import { mapActions, mapGetters } from 'vuex';
import { helpers } from '../Helpers';
import { pluginOptions } from "/vue.config";


export default {
  name: 'BusinessProfile',
  data() {
    return {
      openedTab: 'desc',
      businessHost: null,
      outerCat: {
        name: '',
        id: '',
      },
      swiperOptions: {
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
    }
  },
  components: {
    Profile,
    Amenities,
    Reviews,
    ProductsList,
    BusinessProducts,
    NoPlaces,
    Swiper, 
    SwiperSlide 
  },
  props: ['isMobile'],
  mixins: [helpers],
  watch: {
    productCategories: function(oldCats, newCats)  {
      this.chooseCat(this.productCategories[0].id, this.productCategories[0].name);
    }
  },
  mounted() {    
    this.defaultTab();
    this.$store.state.searchForm = false;
    if(pluginOptions.businesses && pluginOptions.businesses[location.hostname])
    {
        this.businessHost = pluginOptions.businesses[location.hostname];
        this.$route.params.id = this.businessHost.id;
        this.$store.dispatch('getSlides', this.businessHost.slides)
    }


    this.$loadScript('../../../js/bootstrap.bundle.js');
    if(this.$route.params.tab == 3){
      this.openedTab = 'rel';
    }
    this.getBusinessById({
      business_id: this.$route.params.id,
      lang: this.$i18n.locale
    }).then(res => {
      if(this.business.categories > 0){
        this.getBusinessProducts({
          business_id: this.$route.params.id,
          category_id: this.business.categories[0].id,
          lang: this.$i18n.locale
        }).then();
      }
      this.getHotDeals({
        business_id: this.$route.params.id,
        lang: this.$i18n.locale
      })
    }).catch(err => console.log(err));

  },
  methods: {
    ...mapActions(['getBusinessById', 'getBusinessProducts', 'getHotDeals', 'getSlides', 'getBusinessProducts']),
    goReserve(){
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.id}` 
        // path: `${this.$route.params.id}`,
        // params: { id: this.$route.params.id}
      });
    },
    goCat(id) {
      localStorage.clear();
      this.$router.push({ 
        name: 'SearchResults', 
        params: { 
          categoryId : id , 
          locale: this.$i18n.locale
        } 
      });
    },
    changeTab(id) {
      this.openedTab = id;
      this.$route.params.product_id = 0;
    },
    defaultTab() {
      if(this.businessHome) {
        this.openedTab = 'rel'
      }
    },
    carouselNext() {
      $('#homeCarousel').carousel('next')
    },
    carouselPrev() {
      $('#homeCarousel').carousel('prev')
    },
    goApple() {
      window.open(this.businessHost.ios, '_blank')
    },
    goAndroid() {
      window.open(this.businessHost.googlePlay, '_blank')
    },
    // goFacebook() {
    //   window.open(this.businessHost., '_blank')
    // },
    goGallery() {
      window.open(this.businessHost.huawei, '_blank')
    },
    goLink(link) {
      window.open(link ,'_blank')
    },
    pageReset(name) {
      this.currentPage = 1;
      this.categoryName = name;
    },
    chooseCat(id, name) {
      this.changeTab('rel')
      var elmnt = document.getElementById("rel");
      if(this.width == 'sm') {
        elmnt.scrollIntoView(true);
      }else{
        window.scroll({
          top: 900, 
          left: 0, 
          behavior: 'smooth' 
        });
      }
      this.outerCat.name = name;
      this.outerCat.id = id;
      this.$store.state.outerCat = {...this.outerCat};
    },
  },

  computed: {
    ...mapGetters(['business', 'businessProducts', 'width', 'busHotDeals', 'loading', 'profileLoading', 'slides', 'businessProducts']),
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    },
    productCategories() {
      return this.business.categories;
    },
    swiper() {
      return this.$refs.categoriesSlider.$swiper;
    }
  }
}
</script>