<template>
  <section class="container-fluid">
    <div class="row" >
      <div class="col-12" >
         <div 
          class="d-flex justify-content-end mb-4"
          :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'"
        >
          <!-- <div class="align-self-end"> -->
            <!-- <button class="btn-icon-black" @click='swiper.slidePrev()'>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="btn-icon-black" @click='swiper.slideNext()'>
              <i class="fa fa-chevron-right"></i>
            </button>
            </div> -->
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="">
            <div class="header-home">
              {{$t('hotDeals')}}
            </div>
            <div class="subheader-home">
              {{$t('subheader2')}}
            </div>
          </div>
        </div>
        </div>
        <div class="text-center col-12" v-if='!loading || hotDeals.length > 0'>
         <div class="row mt-4">
            <div class="col-12">
               <NoPlaces v-if='!exist' :msg="$t('noPlaces')"/>
                <!-- <div class="row " v-if='hotDeals && hotDeals.length > 0'>
                  <div class="col-8 col-lg-6 mx-auto">
                    <div class="col mb-2 pointer" @click='goProfile(hotDeals[0].business.id, 3, hotDeals[0].id)'>
                        <div class="card text-dark main-card p-3" >
                          <img :src="hotDeals[0].media ? (hotDeals[0].media[0].src || hotDeals[0].media[0].thumb) : 'https://media.tarkett-image.com/large/TH_24567081_24594081_24596081_24601081_24563081_24565081_24588081_001.jpg'" class="card-img-top" alt="...">
                          <div class="card-body">
                            <div class="card-text hotdeals-details"> {{hotDeals[0].cleardescription}}</div>
                            <div class="card-text">
                              <div class="ribbon old-price">from € {{hotDeals[0].price}}</div>
                              <div class="ribbon">to € {{hotDeals[0].final_price}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div> -->
                  <div v-else class="col-12">
                    <Swiper ref='hotdealsSwiper' class="swiper" :options='swiperOptions' :dir="$i18n.locale == 'ar' ? 'rtl': ''">
                        <SwiperSlide class="col-11 col-sm-8 col-md-5 col-lg-3 mb-2 pointer" v-for='item in hotDeals' :key='item.id'>
                          <div class="card hotdeals-contain p-4" @click='goProfile(item.business.id, 3, item.id)'>
                            <div class="hotDeals-image mb-md-3">
                              <img :src="item.media.length > 0 ? item.media[0].url : placeHolderImg" class="card-img-top" :class="item.media.length > 0 ? '' : 'fit-img-none'" alt="...">
                            </div>
                            <div class="card-body text-dark mt-md-4" >
                              <div class="card-text hotdeals-details readmore p-0" :title="item.name"> {{item.name}}</div>
                              <div class="mt-0 p-0">
                                <div class=" opt-name badge-warning">
                                   {{numberWithCommas(item.final_price)}} €
                                </div>
                                <div class=" my-2 old-price ">
                                   {{numberWithCommas(item.price)}} €
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
            </div>
         </div>
        <NoPlaces v-else/>

        </div>
  </section>
</template>

<script>
import NoPlaces from '@/components/misc/NoPlaces.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'HotDeals',

  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      placeHolderImg: require('../assets/images/logo-icon.png')
    }
  },
  components: {
    NoPlaces,
    Swiper, 
    SwiperSlide
  },

  methods: {
    goProfile(id, tab = 3, product_id) {
      this.$router.push({
        name: 'BusinessProfile',
        params: {
          locale: this.$i18n.locale,
          id,
          tab,
          product_id,
        }
      })
    },
    numberWithCommas(x) {
      return x.toString().replace(/[.]/g, ",");
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    }
  },

  props:['hotDeals', 'exist'],
}
</script>