<template>
  <div @click='goCat(category.id)'>
    <div class='cat-card pointer'>
      <div 
        v-if='Home'
        class='cat-card-head'
        :class="ar ? ['text-left'] : 'text-right'"  
      >
        <i 
          class="fa"
          :class="ar ? ['fa-arrow-left'] : 'fa-arrow-right'"
        ></i>
      </div>
      <div 
        class="cat-card-body"
        :class="ar ? ['text-right'] : 'text-left'"    
         :title="category.name"
      >
        <!-- <i class="fa fa-diamond" ></i> -->
        <!-- <div class="my-4 mt-8">
          <mdicon class="mdicon" height='68' width='68' :name='getIcon(category.id)' />
        </div> -->
        <div class="text-center">
          <img :src='category && category.media ? category.media[0].thumb : ""' height='70' width='70' class="my-3 mt-4" />
        </div>
        <div class='cat-name text-center text-ellipsis readmore' style="max-width: 200px" v-html='category.name'></div>
        <!-- <div 
          v-if='Home'
          class="text-sec">{{category.items + ' ' + $t('item')}}
        </div>
        <div v-else class="text-sec">
          {{category.text}}
        </div>-->
      </div> 
    </div>
  </div>
</template>

<script>
import { helpers } from '../../Helpers';
export default {
  name: 'CategoryCard',
  props: ['category'],
  mixins: [helpers],
  methods: {
    goCat() {
      localStorage.clear();
      this.$router.push({
        name: 'SearchResults', 
        params: { categoryId : this.category.id , locale: this.$i18n.locale}
      })
    }
  },
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    },
    Home() {
      return this.$route.name == 'Home';
    }
  },
}
</script>