module.exports = {
  pluginOptions: {
    i18n: {
      locale: 'en',
      fallbackLocale: 'en',
      localeDir: 'locales',
      enableInSFC: false
    },
    businesses: {
      'alnabulsi.de': {
        id: 32,
        name: 'Nabulsi Market',
        logo: 'https://app.snawnaw.com/storage/app/public/6922/56344501.jpg',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.nabulsi.market',
        ios: 'https://apps.apple.com/pe/app/id1558343303',
        huawei:'https://appgallery.huawei.com/#/app/C104001035',
        style: 'alnabulsi',
        favicon: 'https://app.snawnaw.com/storage/app/public/6922/56344501.jpg',
        slides: 'nabulsi_web_main',
      },
      'alnabulsi.test': {
        id: 32,
        name: 'Nabulsi Market',
        logo: 'https://app.snawnaw.com/storage/app/public/6922/56344501.jpg',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.nabulsi.market',
        ios: 'https://apps.apple.com/pe/app/id1558343303',
        huawei:'https://appgallery.huawei.com/#/app/C104001035',
        style: 'alnabulsi',
        favicon: 'https://app.snawnaw.com/storage/app/public/6922/56344501.jpg',
        slides: 'nabulsi_web_main',
      }
    },
  },
  devServer: {
    disableHostCheck: true
  }
}
