<template>
   <div class="container-fluid ">
        <!-- Start Header Centralizer -->
        <div class="row">
          <div class="col-12">
            <div class="row ">
              <div class="home-icons col-12 col-md-1 d-none d-md-block" >
                <div class="home-icons mx-auto mx-xl-3 d-flex flex-column pointer">
                  <div class="my-3 " @click='goApple'>
                    <i class="fa fa-apple"></i>
                  </div>
                  <div class="my-3" @click='goAndroid'>
                    <i class="fa fa-android"></i>
                  </div>
                  <div class="my-3" @click='goGallery'>
                    <img src='../assets/images/logo/appgallery.png'/>
                  </div>
                  <!-- <div class="my-3" @click='goGoogle'> 
                    <i class="fa fa-google"></i>
                  </div> -->
                  <div class="my-3" @click='goFacebook'>
                    <i class="fa fa-facebook"></i>
                  </div>
                  <!-- <div class="my-3" @click='goTwitter'>
                    <i class="fa fa-twitter"></i>
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-md-11" v-if='slides && slides.length > 0'>
                <div id="homeCarousel" class="carousel slide"  data-ride="carousel">
                  <ol class="carousel-indicators" v-for='(slide, index) in slides' :key='index'>
                    <li data-target="#homeCarousel" :data-slide-to="index" class="active"></li>
                    
                  </ol>
                  <div class="carousel-inner my-carousel" v-for='(slide, index) in slides' :key='(index+1)*100'>
                    <div class="carousel-item pointer" :class="index == 0 ? 'active' : ''"  @click="goLink(slide.value)">
                      <img :src="slide.url" >
                    </div>
                    
                  </div>
                  <!-- <div>
                    <button class="btn-icon-def carousel-right-icon" @click='carouselNext'><i class="fa fa-arrow-right"></i></button>
                    <button class="btn-icon-def carousel-left-icon" v-if='width != "sm" || width != "md"' @click='carouselPrev'><i class="fa fa-arrow-left"></i></button>
                  </div> -->
                  <div class="search-pos">
                    <HomeSearch :search='search' :getInspired="getInspired" :goSearchResults="goSearchResults" />
                    <!-- <div class="justify-content-center row" v-if='width != "sm"'>
                      <Chip 
                        class="pointer"
                        v-for='item in getInspired.slice(sliceNumber)' :key='item.id'
                        :item='item'
                        :goSearchResults='goSearchResults'
                      />      
                    </div>      -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Ends Hero Header -->
</template>

<script>
import { helpers } from '../Helpers';
import { animations } from '../Helpers/animations';
import HomeSearch from '@/components/forms/HomeSearch';
// import Chip from '@/components/misc/Chip';

export default {
  name: 'Banner',
  components: {
    HomeSearch,
    // Chip
  },
  mixins: [helpers, animations],

  props: ['getInspired'],
  data() {
    return {
      text: '',
      categoryId: '',
      cityId: '',
      page: 1,
      type: '',
    }
  },

  mounted() {
    this.$store.dispatch('getSlides')
  },
  methods: {
    goSearchResults(categoryId, cityId) {
      localStorage.removeItem('cat');
      localStorage.removeItem('book');
      localStorage.removeItem('city');
      localStorage.removeItem('text');
      this.$router.push({
        name: 'SearchResults', params: {
          categoryId: categoryId,
          cityId: cityId,
        }
      })
    },

    search(text, cityId) {
      localStorage.removeItem('cat');
      localStorage.removeItem('book');
      localStorage.removeItem('city');
      localStorage.removeItem('text');
      this.$router.push({ name: 'SearchResults', params: {
        text: text,
        cityId: cityId,
        categoryId: this.categoryId,
        page: this.page,
        type: this.type
      }})
    },
    chooseCity(id) {
      this.cityId = id;
      console.log(this.cityId)
    },
    carouselNext() {
      $('#homeCarousel').carousel('next')
    },
    carouselPrev() {
      $('#homeCarousel').carousel('prev')
    },

    goApple() {
      window.open('https://apps.apple.com/us/app/id1530699560', '_blank')
    },
    goAndroid() {
      window.open('https://play.google.com/store/apps/details?id=com.snawnawapp', '_blank')
    },
    goTwitter() {

    },
    goGoogle() {

    },
    goFacebook() {
      window.open('https://www.facebook.com/snawnawapp', '_blank')
    },
    goGallery() {
      window.open('https://appgallery.huawei.com/#/app/C103728625', '_blank')
    },
    goLink(link) {
      window.open(link ,'_blank')
    }
    
  },

  computed: {
    cities() {
      return this.$store.getters.cities;
    },
    width() {
      return this.$store.getters.width;
    },
    sliceNumber() {
      return this.width == 'lg' ? 1 : 2;
    },
    slides() {
      return this.$store.getters.slides;
    }
  }
}
</script>

<style scoped>
.search-categories{
  padding: 0;
}
.body-position{
  padding: 27rem 3rem;
  position: relative;
  width: 100%;
  margin: 50px auto;
  margin-top: 90px;
  /* border-radius: 20px; */
}
.inform{
  display: inline-block;
  width: 50%;
}
.inform-2{
  display: inline-block;
  width: 50%;
  border: 0;
  height: 50px;
  background: transparent;
  box-shadow: none;
  color:  rgb(186,19,126);
  font-family: 'Roboto';
  font-size: 17px;
}

.inform-2:focus{
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}
.divider{
  border: 4px solid black;
  border-radius: 20px;
  display: inline;
  margin-inline: 5px;
}
.avatar{
  border-radius: 50%;
}
.category-text{
  margin: 5px 10px;
}
.hero-header:after{
  bottom: -1px;
    border-radius: 20px;
}
.hero-header:after, .pg-header:after, .app:after{
  background-image: unset;
}

@media only screen and (min-width: 900px) {
  .more-categories{
    display: none !important;
  }
}
@media(max-width: 750px) {
  .body-position {
    padding: 20rem 3rem;
    
  }
  .header-centralizer{
    margin-top: 80px;
  }
}

</style>
