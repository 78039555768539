<template>
  <div
    :class="!(['BusinessProfile', 'Home']).includes(currentRoute) ? [''] : ['col-md-12', '']"
    class='col-12'
  >
    <div class="">
      <!--Start Widget Container-->
      <div class="widget-container">
        <!--Start Widget-->
        <div v-if="!(['BusinessProfile', 'Home']).includes(currentRoute)" class=""> <!--start widget-->
          <div class="block">
            <div class="widget-content">
              <form @submit.prevent='refreshSearchResult' class="form-horizontal">
                <div class="form-group">
                  <div class="col-12 inputGroupContainer">
                    <label class="input-label" :class="locale == 'ar' ? 'f-right' : 'f-left'">
                      {{$t('PlaceName')}}
                    </label>
                    <input 
                      v-model="text" 
                      name="name" 
                      id="name" 
                      class="form-control text-input-bot" 
                      @change='refreshSearchResult'
                    >
                  </div>
                </div>
                <div class="form-group mt-2">
                  <div class="col-12 inputGroupContainer" >
                    <label class="input-label" :class="locale == 'ar' ? 'f-right' : 'f-left'">
                      {{$t('PlaceCity')}}
                    </label>
                    <select 
                      v-model="cityId" 
                      name='type' 
                      id='type' 
                      class='form-control select-input-bot'
                      @change='refreshSearchResult'
                    >
                      <option value='' disabled selected> Select City </option>
                      <option value=''> None </option>  
                      <option v-for="city in cities" :key="city.id" :value='city.id'> {{city.name}} </option>
                    </select>
                  </div>
                  <div class="col-12 inputGroupContainer">
                    <label class="input-label" :class="locale == 'ar' ? 'f-right' : 'f-left'">
                      {{$t('PlaceCategory')}}
                    </label>
                    <select 
                      v-model="categoryId" 
                      name='type' 
                      id='type' 
                      class='form-control select-input-bot'
                      @change='refreshSearchResult'
                    >
                      <option value='' disabled selected> Select Category </option>
                      <option value=''> None </option>
                      <option v-for='cat in categories' :key="cat.id" :value='cat.id'> 
                        {{htmlDecode(cat.name)}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 my-4" >
                  <div class="form-group form-check" >
                      <label for='booking' class="form-check-label" :class="locale == 'ar' ? 'f-right' : 'f-left'" >
                          {{$t('availableBookingOnly')}}
                      </label>
                      <input 
                        :class="locale == 'ar' ? 'f-right' : 'f-left'"
                        class="mx-2 mt-1"
                        v-model="booking" 
                        @change='refreshSearchResult' 
                        data-toggle="toggle" 
                        type="checkbox"
                        id='booking'
                      >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--End Widget-->
        <div class="row" v-else> <!--start widget-->
          <div 
              class="col-12 my-4 text-center" 
              v-if='!businessHome'
            >
              <div class="row">
                <div class="col-1 align-self-center" v-if="width != 'sm'">
                  <button class="btn-icon-black pointer" @click='swiper.slidePrev()'>
                    <i class="fa fa-chevron-left"></i>
                  </button>
                </div>
                <div class="col-10">
                  <Swiper class="swiper" ref='categoriesSlider' :options='swiperOptions'>
                    <SwiperSlide v-for="(cat, index) in productCategories" :key='index'>
                      <div class='pointer cat-badge' :class="productCategory == cat.id ? 'active' : ''" @click='chooseCat(cat.id, cat.name)'>
                        <span class="cat-badge-text text-ellipsis" >{{cat.name}}</span>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div class="col-1 align-self-center" v-if="width != 'sm'">
                  <button class="btn-icon-black pointer" @click='swiper.slideNext()'>
                    <i class="fa fa-chevron-right"></i>
                  </button>
                </div>

              </div>
            </div>
      </div>
      </div>
      <!--End Widget Container-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { helpers } from '../../Helpers';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import {pluginOptions} from "/vue.config";

export default {
  name: 'SideFilter',
  props: ['params', 'productCategories', 'pageReset'],
  mixins: [helpers],
  components: {
    Swiper, 
    SwiperSlide 
  },

  mounted() {
    if(!(['BusinessProfile', 'Home']).includes(this.currentRoute)){
      this.initiateParams();
    }else {
      if(this.productCategories) {
        this.chooseCat(this.productCategories[0].id, this.productCategories[0].name);
      }
    }
    if(this.businessHome) {
      this.chooseCat(this.outerCat.id, this.outerCat.name)
    }
  },

  destroyed() {
    if(this.text != '') {
      localStorage.setItem('text', this.text)
    }
    if(this.categoryId != '') {
      localStorage.setItem('cat', this.categoryId)
    }
    if(this.cityId != '') {
      localStorage.setItem('city', this.cityId)
    }
    if(this.booking != false) {
      localStorage.setItem('book', this.booking)
    }
  },

  watch: {
    productCategories: function(oldCats, newCats)  {
      this.chooseCat(this.productCategories[0].id, this.productCategories[0].name);
    },
    outerCat: function(newCat, oldCat) {
      if(oldCat !== newCat) {
        this.chooseCat(newCat.id, newCat.name);
      }
    }
  },
  data() {
    return {
      text: '',
      cityId: '',
      categoryId: '',
      page: 1,
      catPage: 1,
      booking: false,
      productCategory: 0,
      swiperOptions: {
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
    }
  },

  methods: {
    ...mapActions(['getSearchResults', 'getBusinessProducts']),
    initiateParams() {
      if(localStorage.getItem('text') || localStorage.getItem('cat') || localStorage.getItem('city') || localStorage.getItem('book')) {
        if(localStorage.getItem('text')) {
          this.text = localStorage.getItem('text')
        }
        if(localStorage.getItem('cat')) {
          this.categoryId = localStorage.getItem('cat')
        }
        if(localStorage.getItem('city')) {
          this.cityId = localStorage.getItem('city')
        }
        if(localStorage.getItem('book')) {
          this.booking = true;
        }
      }else {
        this.text = this.params ? this.params.text : '';
        this.cityId = this.params ? this.params.cityId : null;
        this.categoryId = this.params ? this.params.categoryId : null;
      }
    },
    refreshSearchResult() {
      if(this.booking == true) {
        this.getSearchResults({
          text: this.text,
          cityId : this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          locale: this.$i18n.locale,
          booking: this.booking
        }).then(res => this.page = 1)
          .catch(err => console.log(err));
      }else {
        this.getSearchResults({
          text: this.text,
          cityId : this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          locale: this.$i18n.locale,
        }).then(res => this.page = 1)
          .catch(err => console.log(err));
      }
    },
    chooseCat(id, name) {
      this.getBusinessProducts({
        business_id: this.$route.params.id,
        category_id: id,
        lang: this.$i18n.locale
      }).then(res => {
        this.productCategory = id;
        this.pageReset(name);
      }).catch(err => {
        console.log(err)
      })
    },
  },

  computed: {
    ...mapGetters(['cities', 'categories', 'outerCat', 'width']),
    locale() {
      return this.$i18n.locale;
    },
    currentRoute() {
      return this.$route.name;
    },
    swiper() {
      return this.$refs.categoriesSlider.$swiper;
    },
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    },
  },

}
</script>

<style scoped>
.my-toggle{
  margin:20px;
}
@media (min-width: 992px){
  .rtl-body .col-md-offset-2 {
      margin-right: 16.66666667%;
      margin-left: 16.66666667%;
  }
}
</style>
