import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueI18nFilter from 'vue-i18n-filter'
import cookies from 'vue-cookies';
import {pluginOptions} from "../vue.config";


Vue.use(VueI18n)
Vue.use(VueI18nFilter)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const businessHome = () => {
    return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
};

let i18nObject = {};
if(businessHome() != null) {
  i18nObject = {
    locale: cookies.get(businessHome().style + '_lang')  || 'de' || process.env.VUE_APP_I18N_LOCALE ,
    fallbackLocale: cookies.get(businessHome().style + '_lang') || 'de' || process.env.VUE_APP_I18N_FALLBACK_LOCALE ,
    messages: loadLocaleMessages()
  }
}else {
  i18nObject = {
    locale: cookies.get('lang')  || 'de' || process.env.VUE_APP_I18N_LOCALE ,
    fallbackLocale: cookies.get('lang') || 'de' || process.env.VUE_APP_I18N_FALLBACK_LOCALE ,
    messages: loadLocaleMessages()
  }
}

export default new VueI18n(i18nObject)
