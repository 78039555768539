<template>
  <div class="container-fluid mx-4" id="rev">
        <div class="row mt-8">
          <div class="col-12">
              <h3 class='listing-section '
                :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
              >
                {{$t('reviews')}} 
                <span class="rating-no ">({{business.reviews.length}})</span>
              
              </h3>
              
              <div class="row">
                <div class="col-12" v-if='business.reviews.length > 0'>
                  <!-- <WriteReview /> -->
                  <!-- Start Review Author Block -->
                  <div class="row">
                    <div class="col-12" v-for='rev in renderReviews' :key='rev.id'>
                      <Review :item='rev' />
                    </div>
                  </div>
                  <!-- End Review Author Block -->
                </div>
                <div v-else class='col-12'>
                  <NoPlaces :msg=" $t('noReviews')" />
                </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import Stars from '@/components/misc/Stars.vue'
import WriteReview from '@/components/forms/WriteReview.vue';
import Review from '@/components/Listings/Review';
import NoPlaces from '@/components/misc/NoPlaces.vue';

import { helpers } from '../../Helpers';
export default {
  data() {
    return {
      page: 1,
    }
  },
  name: 'Profile',
  props: [ 'business' ],
  mixins: [ helpers ],

  components: {
    Stars,
    Review,
    WriteReview,
    NoPlaces
  },

  mounted() {
    this.scroll();
  },

  methods: {
    reputation(rate) {
      if( rate >= 4.5 ) {
        return 'Excelent'
      }else if( rate >= 3.5 && rate < 4.5) {
        return 'Very Good'
      }else if( rate >= 2.5 && rate < 3.5 ) {
          return 'Regular'
      }else if ( rate >= 1.5 && rate < 2.5 ) {
        return 'Not Good'
      }else return 'Bad'

    }
  },
  computed: {
    width() {
      return this.$store.getters.width;
    },
    renderReviews() {
      if ( this.page * 10 > this.business.reviews.length) {
         return this.business.reviews.slice(0, this.business.reviews.length)
      }else return this.business.reviews.slice(0, this.page * 10)
    }
  },

  methods: {
    nextPage() {
      this.page++
    },
    scroll () {
      window.onscroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.nextPage()
        }
      };
    }
  }
}
</script>

<style scoped>
.author-name{
  margin: 10px 5px;
}
</style>