<template>
  <nav 
    class="navbar sticky-top navbar-expand-lg py-md-3 px-md-4 px-lg-5" 
    style="padding-bottom: 5px !important;"
    :class="locale == 'ar' ? 'flex-row-reverse' : '' "
  >
    <router-link
            :to="{ name: 'Home', params: { locale: $i18n.locale }}"
          >
          <a class="navbar-brand p-0" @click="CloseNav()">
            <img v-if="!businessHome" :height="width == 'sm' ? '36px' : '48x'" src='../assets/images/logo/logo3.png' />
            <div v-else >
                <img :src="businessHome.logo" style="width: 50px;vertical-align: middle;" />
                <!-- <span class="business-logo-name pl-3">{{businessHome.name}}</span> -->
            </div>
          </a>
      </router-link>
      <div class="header-download d-none d-md-block d-lg-none" :class="locale == 'ar' ? 'mr-auto' : 'ml-auto'">
        <div class="store-icons row d-flex justify-content-around">
          <i class="fa fa-android pointer mx-3" @click='goStore'></i>
          <i class="fa fa-apple pointer mx-3" @click='goApple'></i>
          <span class="appgallery pointer mx-3" @click='goGallery'>
            <img src='../assets/images/logo/appgallery.png'/>
          </span>
        </div>
      </div>
      <div v-if="width == 'md' || width == 'sm'" class="pointer btn-icon-sec-square" :class="locale == 'ar' ? ['mr-auto','mr-md-0'] : ['ml-auto', 'ml-md-0']" @click="$router.push({name: 'Cart'})">
        <i class="fa fa-shopping-cart"></i>
        <span class="badge">{{cartItems ? cartItems.length : 0}}</span>    
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-list"></i>
      </button>
    <div class="collapse navbar-collapse" id="navbarText" :class="locale == 'ar' ? 'flex-row-reverse' : '' ">
      <ul class="navbar-nav mx-auto" :class="locale == 'ar' && (width != 'sm' && width != 'md') ? 'flex-row-reverse' : '' ">
        <li class="nav-item" @click="CloseNav()">
          <router-link
              :to="{ name: 'Home', params: { locale: $i18n.locale }}"
              class="pointer nav-link"
              v-if='width == "lg"'
            >
              {{$t('Home')}}
          </router-link>
        </li>
        <li class="nav-item" v-if="!businessHome" @click="CloseNav()">
          <router-link
              :to="{ name: 'Reservations', params: { locale: $i18n.locale }}"
              class="pointer nav-link"
            >
              {{$t('Reservations')}}
          </router-link>
          <!-- <router-link
              :to="{ name: 'SearchResults', params: { locale: $i18n.locale }}"
              class="pointer nav-link"
            >
              {{$t('Reservations')}}
          </router-link> -->
        </li>
        <li class="nav-item" @click="CloseNav()">
          <router-link
              :to="{ name: 'Privacy', params: { locale: $i18n.locale }}"
              class="pointer nav-link"
            >
              {{$t('privacy')}}
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle pointer" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- {{$t('languages')}} -->
            <span >
              <img class="langs-select" width='20px' :src='flag($i18n.locale)'/>
              <span>{{ $i18n.locale.toUpperCase() }}</span>
            </span>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item pointer" v-for="(lang, index) in locales" :key='index' >
              <div @click="localeSwitch(lang);CloseNav()">
                <i width='20px' class="fa fa-circle" v-show="locale == lang"></i>
                <img class="langs-select" width='20px' :src='flag(lang)'/>
                <span>{{ lang.toUpperCase() }}</span>
              </div>
            </a>
          </div>
        </li>
        <!-- <li class="nav-item pl-3 pt-2">
            <form @submit.prevent='search(text)' class="row justify-content-center" v-if="$route.name != 'Home' && $route.name != 'Login' && $route.name != 'Register' && $route.name != 'Privacy'">
             <div class="input-group">
               
               <input 
                  :class=" locale == 'ar' ? 'text-right' : 'text-left'"
                  class='form-control' 
                  v-model='text'
                  :placeholder="$t('line1')"  
                />
              <div class="input-group-append">
                 <button class="btn btn-icon-sec-square m-0" style="height: 38px;padding: 0px;" type="submit" id="button-addon1"><i class="fa fa-search"></i></button>               
              </div>
             </div>
                
            </form>
        </li> -->
        <div class="header-download" v-if="widthPx > 1280">
          <div class="store-icons row d-flex justify-content-around">
            <i class="fa fa-android pointer mx-3" @click='goStore'></i>
            <i class="fa fa-apple pointer mx-3" @click='goApple'></i>
            <span class="appgallery pointer mx-3" @click='goGallery'>
              <img src='../assets/images/logo/appgallery.png'/>
            </span>
          </div>
        </div>
      </ul>
      <div class="d-flex justify-content-center"  :class="locale == 'ar' ? 'flex-row-reverse' : '' ">
          <div v-if='profileRoute' class="pointer search-square-btn" @click="searchVisible()" >
            <i class="fa fa-search"></i>
          </div>
          <div v-if="width == 'lg' || width == 'xl'" class="pointer btn-icon-sec-square" @click="CloseNav();$router.push({name: 'Cart'})" >
            <i class="fa fa-shopping-cart"></i>
            <span class="badge">{{cartItems ? cartItems.length : 0}}</span>    
          </div>
          <button class="pointer btn-sec-square" @click="$router.push({name: 'MyOrders'})">{{$t('myOrders')}}</button>
      </div>
    </div>
  </nav>
</template>

<script>
import {pluginOptions} from "/vue.config";

export default {
  name: 'AppHeader',
  data() {
    return {
      locales: this.$i18n.availableLocales,
      text: '',
      city: ''
    }
  },
  props: ['searchVisible', 'searchForm'],
  mounted() {
    // $(document).ready(function () {
         

    //     $('html').click(function (event) {
    //         var clickover = $(event.target);
    //         var _opened = $(".navbar-collapse").hasClass("navbar-collapse in");
    //         if (_opened === true && !clickover.hasClass("navbar-toggle")) {
    //             CloseNav();
    //         }

    //     });
    // });
  },
  computed: {
    profileRoute() {
      return this.$route.name == 'BusinessProfile';
    },
    locale() {
      return this.$i18n.locale;
    },
    cartItems() {
      return this.$store.getters.cartItems;
    },
    width() {
      return this.$store.getters.width;      
    },
    widthPx() {
      return this.$store.getters.widthPx;      
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    businessHome()
    {
        return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? pluginOptions.businesses[location.hostname] : null
    },
  },

  methods: {
    localeSwitch(locale) {
      if(locale !== this.$i18n.locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale}});
        this.$router.push(to.location); 
        this.$router.go(); 
      }
    },
    flag(lang) {
      return require('../assets/images/flags/' + lang + 'circle.png')
    },

    goLogin() {
      this.$router.push({
        name: 'Login'
      })
    },
     goSignup() {
      this.$router.push({
        name: 'Register'
      })
    },
    search(text) {
      localStorage.removeItem('cat');
      localStorage.removeItem('book');
      localStorage.removeItem('city');
      localStorage.removeItem('text');
      if(this.$route.name == 'SearchResults') {
        this.$router.go({ name: 'SearchResults', params: {
          text: text,
          cityId: this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          type: this.type
        }})
      }else {
        this.$router.push({ name: 'SearchResults', params: {
          text: text,
          cityId: this.cityId,
          categoryId: this.categoryId,
          page: this.page,
          type: this.type
        }})
      }
    },
    CloseNav() {
      $('#navbarText').collapse('hide');
        
    },
    expand() {
      if($('#langDropdown').hasClass('open')){
        $('#langDropdown').removeClass('open')
      }else $('#langDropdown').addClass('open')
    },
    goStore() {
      window.open(this.businessHome ? this.businessHome.googlePlay : 'https://play.google.com/store/apps/details?id=com.snawnawapp', '_blank')
    },
    goApple() {
      window.open(this.businessHome ? this.businessHome.ios : 'https://apps.apple.com/us/app/id1530699560', '_blank')
    },
    goGallery() {
      window.open(this.businessHome ? this.businessHome.huawei : 'https://appgallery.huawei.com/#/app/C103728625', '_blank')
    }
  }

}
</script>


 