import icons from '../Helpers/icons.json';

export const helpers = {
 methods: {
  htmlDecode(input) {
    var e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  },
  getIcon(payload) {
    let myItem  = icons.icons.find(item => item.id == payload); 
    return myItem != undefined ? myItem.icon : 'clover';
  },
  getBook(payload) {
    let myItem  = icons.icons.find(item => item.id == payload); 
    return myItem != undefined ? myItem.book : false;
  },
  $tr(translate) {
    if (typeof translate === 'string') {
      return this.$i18n.t(translate)
    } else if (translate == '') {
      return translate;
    }
    return translate[this.$i18n.locale]
  },
  monthText(month) {
    const months = [
      { id: 1, text: 'January', abbr: 'Jan'},
      { id: 2, text: 'February', abbr: 'Feb'},
      { id: 3, text: 'MArch', abbr: 'Mar'},
      { id: 4, text: 'April', abbr: 'Apr'},
      { id: 5, text: 'May', abbr: 'May'},
      { id: 6, text: 'June', abbr: 'Jun'},
      { id: 7, text: 'July', abbr: 'Jul'},
      { id: 8, text: 'August', abbr: 'Aug'},
      { id: 9, text: 'September', abbr: 'Sep'},
      { id: 10, text: 'October', abbr: 'Oct'},
      { id: 11, text: 'Novemebr', abbr: 'Nov'},
      { id: 12, text: 'December', abbr: 'Dec'},
    ];
    return months.find(({id}) => month == id);
  },
  scrollElement(id) {
    var elmnt = document.getElementById(id);
    elmnt.scrollIntoView();
  }
 }
}