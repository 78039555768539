

export const animations = {
  data() {
    return {
      image: [],
      appending: [],
      imageCanvas: [],
      imageCanvasContext: [],
      lineCanvas: [],
      lineCanvasContext: [],
      pointLifetime: [],
      points : [],
      toggler: '.navbar-toggle',
      pagewrapper: '#page-content',
      navigationwrapper: '.navbar-header',
      menuwidth: '100%',
      slidewidth:'300px',
      menuneg:'-300px',
      slideneg:'-300px',
      selected:'#slidemenu, #page-content, body, .navbar, .navbar-header',
    }
  },
  methods : {

    stopScrolling(action){
      if(action===1)
        $("body").addClass("stop-scrolling");
      else
        $("body").removeClass("stop-scrolling");
    },

    Events() {
        $(window).on("resize", () =>  {
          this.resetElements();
        });
    
        // $(window).on("scroll",() => {
        //   // checkElementsVisibility();
        // });
    
        // $(".feat-posts-list").on("scroll",() => {
        //   // checkElementsVisibility();
        // });
    
        $('.hero-search input[type="text"]').on("click keyup keydown keypress oninput",() => {
          var _ = $(this);
          if(_.val()===""){
            $(".typingEffect,.typed-cursor").remove();
            $(".typingEffect,.typed-cursor").each(() => {
                $(this)[0].parentNode.removeChild($(this)[0]);
            });
            $(this).addClass("inFocus");
          }
        });
    
        $('.search-cities-toggle').on("click",function() {
          $(this).closest('.hero-header').addClass("open-cities-list");
          $(".search-cities").css({top:"-1000px"});
          $(".search-cities").stop().animate({top:[0, 'easeOutExpo']},{duration:1500});
        });
    
        $('body').on("click",".search-cities a",function(e) {
          var _ = $(this);
          if(!$(".hero-header").hasClass("open-cities-list")){
            setTimeout(() => {
              _.closest('.hero-header').addClass("open-cities-list");
              $(".search-cities").css({top:"-1000px"});
              $(".search-cities").stop().animate({top:[0, 'easeOutExpo']},{duration:1500});
            },50);
          }else{
            if($(e.target).is(".current")||$(e.target).parent().is(".current")){
              e.preventDefault();
              e.stopPropagation();
              $(this).closest('.hero-header').removeClass("open-cities-list");
            }
            else if($(e.target).is(".go-more-cities")||$(e.target).parent().is(".go-more-cities")){
              // Go to the current href normally
            }
            else{
              e.preventDefault();
              e.stopPropagation();
              var lastCurrent = $(".search-cities .current");
              lastCurrent.removeAttr("class");
              _.addClass("current");
              $(".chosen-city").val(_.attr("data-city-id"));
              $(this).closest('.hero-header').removeClass("open-cities-list");
              var _HTML = _.prop("outerHTML"),
                  lastCurrentHTML = lastCurrent.prop("outerHTML");
              _.before(lastCurrentHTML);
              lastCurrent.before(_HTML);
              lastCurrent.remove();
              _.remove();
              lastCurrent.each(() => {
                $(this)[0].parentNode.removeChild($(this)[0]);
              });          
              _.each(() => {
                $(this)[0].parentNode.removeChild($(this)[0]);
              });
            }
          }
        });
    
        $("body").on("click",(e) => {
          var field = '.hero-search input[type="text"]';
          if(!$(e.target).is(".hero-search input[type='text']") && $(field).val()===""){
            if($(field).hasClass("inFocus")){
              $(field).removeClass("inFocus");
              $(".typingEffect,.typed-cursor").remove();          
              $(".typingEffect,.typed-cursor").each(() => {
                $(this)[0].parentNode.removeChild($(this)[0]);
              });
              $('.hero-search fieldset').append(typedAnimatedTexts);
              // startTypingEffect();
            }
          }
        });
    
        $(".hero-header .search-submit").on({
          'mouseenter': (e) => {
            $(".hero-search-icon").addClass("hover");
          },
          'mouseleave': (e) => {
            $(".hero-search-icon").removeClass("hover");
          }
        });
    
        $(".show-categories").on("click",() => {
          var _ = $(this);
          setTimeout(() => {
            if(_.hasClass("opt-hidden")){
              $(".show-categories, .search-categories").removeClass("opt-hidden");
              $(".hero-search").css({display: "none"});
            }
          },5);
        });
    
        $(".feat-posts .toggle").on("click",() => {
          if($(".feat-posts").width()>30){
            $(".hero-search,.feat-posts").addClass("hide-feat");
            $(".hero-search").siblings('.search-categories').addClass("hide-feat");
          }
          else{
            $(".hero-search,.feat-posts").removeClass("hide-feat");
            $(".hero-search").siblings('.search-categories').removeClass("hide-feat");
          }
          //videoRescale();
          resizeCanvases();
        });
    
        $(".background-caption").on({
          'mouseenter': (e) => {
            var _ = $(this).find(".bg-title");
            $(".hero-arrow").addClass("hover").stop().animate({left:$(this).width()/2+10,bottom:$(this).height()+5},{duration:300});
            _.html(_.html() + " <span>+</span>");
          },
          'mouseleave': (e) => {
            var _ = $(this).find(".bg-title");
            $(".hero-arrow").removeClass("hover").stop().animate({left:10,bottom:45},{duration:300});
            _.html(_.html().replace(" <span>+</span>", ""));
          }
        });
    
        $(".logo a").not(".logo-landing").on({
          'mouseenter': (e) => {
            $(this).stop().animate({opacity:0.7});
          },
          'mouseleave': (e) => {
            $(this).stop().animate({opacity:1});
          }
        });
    
        var catScrolQtd = 0;
        if($(".search-categories .categories").length)
        catScrolQtd = $(".search-categories .categories")[0].scrollHeight;
        $(".search-categories .more-categories").on({
          'click': (e) => {
            var __ = $(".search-categories .categories"),
            _ = $(".search-categories");
            _.stop().animate({"max-height" : 800},{duration:600});
            $(this).css({display:"none"});
            $(".search-categories .minus-categories").css({display:"block"});
            $(".search-cities-toggle,.search-cities").css({display:"none"});
            //videoRescale();
            resizeCanvases();
          }
        });
    
        $(".search-categories .minus-categories").on({
          'click': (e) => {
            var __ = $(".search-categories .categories"),
            _ = $(".search-categories");
            if(_.height()>120){
              _.stop().animate({"max-height" : [93, 'easeOutExpo']},{duration:200});
              $(this).css({display:"none"});
              $(".search-categories .more-categories").css({display:"block"});
            }
            else {
              __.stop().animate({marginTop:parseInt(__.css('margin-top').replace(/[^-\d\.]/g, ''),10) +76});
              catScrolQtd = catScrolQtd + 76;
              $(".search-categories .more-categories").css({display:"block"});
              if(__[0].scrollHeight-catScrolQtd<70){
                $(this).css({display:"none"});
              }
            }
            $(".search-cities-toggle,.search-cities").css({display:"block"});
            //videoRescale();
            resizeCanvases();
          }
        });
    
        $(".app-links a").on({
          "mouseenter" : () => {
            $(".app-img img").addClass("hover");
          },
          "mouseleave" : () => {
            $(".app-img img").removeClass("hover");
          }
        });
    
        $("body").on("click",".full-dimming-overlay",() => {
          if($("#slidemenu").css('left').replace(/[^-\d\.]/g, '')==="0"){
            $(this.toggler).trigger("click");
          }
        });
    
        $("#slide-nav").on("click", this.toggler,  (e) => {
          var selected = $(this).hasClass('slide-active');
          if(!selected){
            this.stopScrolling(1);
            $(".full-dimming-overlay").css({display:"block"}).stop().animate({opacity:0.88},{duration:1000});
          }
          else{
            if($(".search-popup").css("left")!=="0px"&&$(".login-popup").css("left")!=="0px"&&$(".review-popup").css("left")!=="0px"&&$(".pm-popup").css("left")!=="0px"){
              this.stopScrolling(0);
            }
            $(".full-dimming-overlay").stop().animate({opacity:0},{duration:1000,complete:() => {
              $(".full-dimming-overlay").css({display:"none"});
            }});
          }
          $('#slidemenu').stop().animate({
              left: selected ? menuneg : '0px'
          });
          $('#navbar-height-col').stop().animate({
              left: selected ? slideneg : '0px'
          });
          $(this.pagewrapper).stop().animate({
              left: selected ? '0px' : this.slidewidth
          });
          $(this.navigationwrapper).stop().animate({
              left: selected ? '0px' : '225px'
          });
          $(this).toggleClass('slide-active', !selected);
          $('#slidemenu').toggleClass('slide-active');
          $('#page-content, .navbar, body, .navbar-header').toggleClass('slide-active');
        });
    
        $(".listing-item-link").on({
          "mouseenter": () => {
            var _ = $(this);
            if(_.parent().parent().parent().hasClass("grid2")||_.parent().parent().parent().hasClass("grid3")){
              _.siblings('a, .listing-item-rating').not(".listing-item-author").stop().animate({top: "-10px", opacity: 0});
              _.siblings('.listing-item-author').stop().animate({top: "110px", opacity: 0});
              _.siblings('.listing-item-date').stop().animate({top: "-60px", opacity: 0});
            }
            else if(_.parent().parent().parent().parent().hasClass("grid6")&&_.parent().parent().parent().parent().hasClass("rounded-pic")){
              _.siblings('a').not(".listing-item-author").stop().animate({top: "-20px", opacity: 0});
              _.find('.listing-item-rating').stop().animate({top: "-20px", opacity: 0});
              _.siblings('.listing-item-author').stop().animate({top: 0, opacity: 0});
              _.siblings('.listing-item-date').stop().animate({top: "-60px", opacity: 0});
            }
            else{
              _.siblings('a, .listing-item-rating').not(".listing-item-author").stop().animate({top: "-10px", opacity: 0});
              _.siblings('.listing-item-author').stop().animate({top: 0, opacity: 0});
              _.siblings('.listing-item-date').stop().animate({top: "-60px", opacity: 0});
            }
          },
          "mouseleave": () => {
            var _ = $(this);
            if(_.parent().parent().parent().hasClass("grid2") || _.parent().parent().parent().hasClass("grid3")){
              _.siblings('.listing-item-rating').stop().animate({top: "10px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-rating').css({top:""});
              }});
              _.siblings('.category-icon').stop().animate({top: "25px", opacity: 1},{complete:() => {
                _.siblings('.category-icon').css({top:""});
              }});
              _.siblings('.listing-item-author').stop().animate({top: "84px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-author').css({top:""});
              }});
              _.siblings('.listing-item-date').stop().animate({top: "-5px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-date').css({top:""});
              }});
            }
            else if(_.parent().parent().parent().parent().hasClass("grid6")&&_.parent().parent().parent().parent().hasClass("rounded-pic")){
              _.find('.listing-item-rating').stop().animate({top: "-8px", opacity: 1},{complete:() => {
                _.find('.listing-item-rating').css({top:""});
              }});
              _.siblings('a').not(".listing-item-author").stop().animate({top: "35px", opacity: 1},{complete:() => {
                _.siblings('a').not(".listing-item-author").css({top:""});
              }});
              _.siblings('.listing-item-author').stop().animate({top: "-44px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-author').css({top:""});
              }});
              _.siblings('.listing-item-date').stop().animate({top: "-5px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-date').css({top:""});
              }});
            }
            else if(_.parent().parent().parent().hasClass("squared")||(_.parent().parent().parent().hasClass("squared-mobile")&&viewport().width<=600)){
              _.siblings('a, .listing-item-rating').not(".listing-item-author").stop().animate({top: "6%", opacity: 1},{complete:() => {
                _.siblings('a, .listing-item-rating').not(".listing-item-author").css({top:""});
              }});
              _.siblings('.listing-item-author').stop().animate({top: "-44px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-author').css({top:""});
              }});
              _.siblings('.listing-item-date').stop().animate({top: "-5px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-date').css({top:""});
              }});
            }
            else{
              _.siblings('a, .listing-item-rating').not(".listing-item-author").stop().animate({top: "12%", opacity: 1},{complete:() => {
                _.siblings('a, .listing-item-rating').not(".listing-item-author").css({top:""});
              }});
              _.siblings('.listing-item-author').stop().animate({top: "-44px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-author').css({top:""});
              }});
              _.siblings('.listing-item-date').stop().animate({top: "-5px", opacity: 1},{complete:() => {
                _.siblings('.listing-item-date').css({top:""});
              }});
            }
          }
        });
    
        $(".gallery-item a").on("mouseenter",() => {
          $(this).parent().addClass("dark-overlay");
          $(this).parent().find(".gallery-item-caption").stop().animate({opacity:0},{duration:1000});
        });
    
        $(".gallery-item a").on("mouseleave",() => {
          $(".gallery-item a").parent().removeClass("dark-overlay");
          $(this).parent().find(".gallery-item-caption").stop().animate({opacity:1},{duration:1000});
        });
    
        $("body").on("click",".header-search-button,.search-pop-button", (e) => {
          e.preventDefault();
          e.stopPropagation();
          if(!$(".search-popup").hasClass("showing-search")){
            this.stopScrolling(1);
            $(".search-popup").addClass("showing-search").css({left:"-100%",zindex:999}).stop().animate({left:0,opacity:1},{duration:500});
            $(".review-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
            $(".pm-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
            $(".login-popup").css({zIndex:998}).stop().animate({left:"-200%",opacity:0.99},{duration:500});
            setTimeout(() => {
              $(".review-popup").removeClass("showing-review");
              $(".pm-popup").removeClass("showing-pm");
              $(".login-popup").removeClass("showing-login");
            },500);
          }
          else{
            this.stopScrolling(0);
            $(".search-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
            setTimeout(() => {
              $(".search-popup").removeClass("showing-search");
            },500);
          }
        });
    
        $("body").on("click",".search-popup .back-site", () => {
          this.stopScrolling(0);
          $(".search-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
          setTimeout(() => {
            $(".search-popup").removeClass("showing-search");
          },500);
        });
    
        $('#login-form-link').on("click",(e) =>  {
          $("#login-form").delay(100).fadeIn(100);
          $("#register-form").fadeOut(100);
          $('#register-form-link').removeClass('active');
          $(this).addClass('active');
          e.preventDefault();
        });
    
        $('#register-form-link').on("click",(e) =>  {
          $("#register-form").delay(100).fadeIn(100);
          $("#login-form").fadeOut(100);
          $('#login-form-link').removeClass('active');
          $(this).addClass('active');
          e.preventDefault();
        });
    
        $(".quick-menu a").on("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
          var _ = $(this);
          var qMenu = $(".quick-menu a");
          var show = $(".quick-menu a.show-menu");
          var hidde = $(".quick-menu a.hidde-menu");
          if(_.hasClass("hide-menu")){
            qMenu.addClass("hidden");
            show.removeClass("hidden");
            qMenu.parent().addClass("hidden-menu");
          }
          else if(_.hasClass("show-menu")){
            qMenu.removeClass("hidden");
            show.addClass("hidden");
            qMenu.parent().removeClass("hidden-menu");
          }
          if(_.hasClass("gal")){
            $("#gal").ScrollTo();
            $('html, body').stop().animate({
              scrollTop: $("#gal").offset().top - 112
            }, 600);
          }
          if(_.hasClass("desc")){
            $("#desc").ScrollTo();
            $('html, body').stop().animate({
              scrollTop: $("#desc").offset().top - 124
            }, 600);
          }
          if(_.hasClass("rev")){
            $("#rev").ScrollTo();
            $('html, body').stop().animate({
              scrollTop: $("#rev").offset().top - 124
            }, 600);
          }
          if(_.hasClass("mp")){
            $("#mp").ScrollTo();
            $('html, body').stop().animate({
              scrollTop: $("#mp").offset().top - 124
            }, 600);
          }
          if(_.hasClass("rel")){
            $("#rel").ScrollTo();
            $('html, body').stop().animate({
              scrollTop: $("#rel").offset().top - 124
            }, 600);
          }
          if(_.hasClass("pvt")){
            setTimeout(() => {
              $(".pvt").trigger("mouseleave");
            },2000);
            this.stopScrolling(1);
            $(".pm-popup").addClass("showing-pm").css({left:"-100%"}).stop().animate({left:0,opacity:1},{duration:500});
          }
          if ($.isFunction($.fn.tooltip)) {
            $('[data-toggle="tooltip"]').tooltip('hide');
          }
        });
    
        $(".listing-item-rating").on("click",() => {
          $("#rev").ScrollTo();
          $('html, body').stop().animate({
            scrollTop: $("#rev").offset().top - 124
          }, 600);
        });
    
        $("body").on("click",".write-review a", (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.stopScrolling(1);
          $(".review-popup").addClass("showing-review").css({left:"-100%"}).stop().animate({left:0,opacity:1},{duration:500});
        });
    
        $("body").on("click",".review-popup .back-site", () => {
          this.stopScrolling(0);
          $(".review-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
          setTimeout(() => {
            $(".review-popup").removeClass("showing-review");
          },500);
        });
    
        $("body").on("click",".user-login,.sign-in", (e) => {
          e.preventDefault();
          e.stopPropagation();
          if(!$(".login-popup").hasClass("showing-login")){
            this.stopScrolling(1);
            $(".login-popup").addClass("showing-login").css({left:"-100%",zIndex:999}).stop().animate({left:0,opacity:1},{duration:500});
            $(".review-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
            $(".pm-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
            $(".search-popup").css({zIndex:998}).stop().animate({left:"-200%",opacity:0.99},{duration:500});
            setTimeout(() => {
              $(".search-popup").removeClass("showing-search");
              $(".review-popup").removeClass("showing-review");
              $(".pm-popup").removeClass("showing-pm");
            },500);
          }
          else{
            stopScrolling(0);
            $(".login-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
            setTimeout(() => {
              $(".login-popup").removeClass("showing-login");
            },500);
          }
        });
    
        $("body").on("click",".login-popup .back-site", () => {
          this.stopScrolling(0);
          $(".login-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
          setTimeout(() => {
            $(".login-popup").removeClass("showing-login");
          },500);
        });
    
        $("body").on("click",".pm-popup .back-site", () => {
          this.stopScrolling(0);
          $(".pm-popup").stop().animate({left:"-200%",opacity:0.99},{duration:500});
          setTimeout(() => {
            $(".pm-popup").removeClass("showing-pm");
          },500);
        });
    
        $("body").on("click",".change-color-button", (e) => {
          e.preventDefault();
          e.stopPropagation();
          var _ = $(this);
          _.animate({right:"-500px", opacity:0});
          showColors();
        });
    
        $(".logo-landing").on("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
    
  
    },

    setHeroSlideshow() {        
      $(".hero-slideshow").remove();
      $(".hero-slideshow").each(() => {
          $(this)[0].parentNode.removeChild($(this)[0]);
      });
      if($(".h-slideshow").length){
        if((!this.isMobile()||"forceSlideshowOnMobile" in window) && typeof hero_slideshow === "object"){          
          if(typeof hero_slideshow[0]==="string"){
            var s = 0;
            $(".h-slideshow").prepend('<div class="hero-slideshow"></div>');
            for(s=0;s< hero_slideshow.length;s++){
              $(".hero-slideshow")
              .append('<div class="hero-slide" style="background-image: url(' + hero_slideshow[s] + ');"></div>');
            }
            var imgs = $(".hero-slideshow .hero-slide");
            var i = -1;
            (function slideshow() {
              setTimeout(() => {
                i = (i + 1) % imgs.length;
                $(imgs).not(imgs[i]).removeClass("current-slide");
                $(imgs[i]).addClass("current-slide");
                setTimeout(slideshow, 6000);
              },500);
            })();
          }
          if("forceSlideshowOnMobile" in window){
              $(".hero-slideshow").attr('style', 'display: block !important');
              $(".is-mobile .h-slideshow .hero-image").attr('style', 'display: none !important');
          }            
        }
      }
    },

    checkHeroFeatPosts(){
      setTimeout(() => {
        if(!$(".hero-header .feat-posts").length){
          var hs = $(".hero-search").parent().width();
          if(window.innerWidth > 767){
              $(".hero-search,.search-categories").stop().animate({width:hs*0.8,left:hs*0.1});
          }
          else{
            $(".hero-search,.search-categories").stop().animate({width:hs*0.9,left:hs*0.05});
          }
        }
      },1000);
    },

    startTypingEffect() {
      var typedAnimatedTexts = "";
      var field = '.hero-search input[type="text"]';
      if(typedAnimatedTexts==="")
      typedAnimatedTexts = $(".typingEffect").prop("outerHTML");
      if($(field).val()==="" && window.innewWidth > 480){
        $(field).attr("placeholder","");
        $(field).removeClass("inFocus");
        $(".typingEffect,.typed-cursor").remove();
        $(".typingEffect,.typed-cursor").each(() => {
            $(this)[0].parentNode.removeChild($(this)[0]);
        });
        $('.hero-search fieldset').append(typedAnimatedTexts);
        if ($.isFunction($.fn.typed)) {
          $(".typingEffect").typed({
              strings: $(".typingEffect").attr("data-title").split("//"),
              typeSpeed: 100,
              loop: true
          });
        }
      }
      else{
        $(field).attr("placeholder",$(field).attr("data-placeholder"));
      }
      if($(field).val()!==""){
        $(".typingEffect,.typed-cursor").remove();
        $(".typingEffect,.typed-cursor").each(() => {
            $(this)[0].parentNode.removeChild($(this)[0]);
        });
        $(field).addClass("inFocus");
      }
    },

    typingOnLanding(){
      var landingTexts = "";
      if($(".typeLanding").length){
        var field = '.typingEffect';
        if(landingTexts==="")
        landingTexts = $(".typingEffect").prop("outerHTML");
        if ($.isFunction($.fn.typed)) {
          $(".typeLanding").typed({
              strings: $(".typeLanding").attr("data-title").split("//"),
              typeSpeed: 100,
              loop: true
          });
        }
      }
    },

    isMobile(){
    	var isMobile = ('ontouchstart' in document.documentElement || navigator.userAgent.match(/Mobi/)?true:false);
    	return isMobile;
    },

    getDistanceBetween(a, b) {
      return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
    },

    start() {
      document.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('resize', this.resizeCanvases);
      this.appending.appendChild(this.imageCanvas);
      this.resizeCanvases();
      this.tick();
    },

    onMouseMove(event) {
      var scroll = 0;
      if(!$(".search-popup").length)
      scroll = $(document).scrollTop();
      this.points.push({
        time: Date.now(),
        x: event.clientX,
        y: event.clientY + scroll
      });
    },

    tick() {
      let pointLifetime = this.pointLifetime;
      this.points = this.points.filter((point) => {
        var age = Date.now() - point.time;
        return age < pointLifetime;
      });
      this.drawLineCanvas();
      this.drawImageCanvas();
      requestAnimationFrame(this.tick);
    },

    drawLineCanvas() {
      var minimumLineWidth = 40;  
      var maximumLineWidth = 100;
      var lineWidthRange = maximumLineWidth - minimumLineWidth;
      var maximumSpeed = 50;
      this.lineCanvasContext.clearRect(0, 0, this.lineCanvas.width, this.lineCanvas.height);
      this.lineCanvasContext.lineCap = 'round';
      this.lineCanvasContext.shadowBlur = 20;
      this.lineCanvasContext.shadowColor = '#000';
      for (var i = 1; i < this.points.length; i++) {
        var point = this.points[i];
        var previousPoint = this.points[i - 1];
        var distance = this.getDistanceBetween(point, previousPoint);
        var speed = Math.max(0, Math.min(maximumSpeed, distance));
        var percentageLineWidth = (maximumSpeed - speed) / maximumSpeed;
        this.lineCanvasContext.lineWidth = minimumLineWidth + percentageLineWidth * lineWidthRange;
        var age = Date.now() - point.time;
        var opacity = (this.pointLifetime - age) / this.pointLifetime;
        this.lineCanvasContext.strokeStyle = 'rgba(0, 0, 0, ' + opacity + ')';
        this.lineCanvasContext.beginPath();
        this.lineCanvasContext.moveTo(previousPoint.x, previousPoint.y);
        this.lineCanvasContext.lineTo(point.x, point.y);
        this.lineCanvasContext.stroke();
      }
    },

    getDistanceBetween(a, b) {
      return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
    },

    drawImageCanvas() {
      var top = 0, left = 0;
      var width =  this.imageCanvas.width;
      var height =  this.imageCanvas.width / this.image.naturalWidth * this.image.naturalHeight;
      if (height <  this.imageCanvas.height) {
        width =  this.imageCanvas.height / this.image.naturalHeight * this.image.naturalWidth;
        height =  this.imageCanvas.height;
        left = -(width-this.imageCanvas.width)/2;
      }
      else{
        top = -(height-this.imageCanvas.height)/2;
      }

      this.imageCanvasContext.clearRect(0, 0,  this.imageCanvas.width,  this.imageCanvas.height);
      this.imageCanvasContext.globalCompositeOperation = 'source-over';
      this.imageCanvasContext.drawImage(this. image, left, top, width, height);
      this.imageCanvasContext.globalCompositeOperation = 'destination-in';
      this.imageCanvasContext.drawImage(this.lineCanvas, 0, 0);

    },

    setCanvasEffect(){
      if(!("disableRubber" in window)){
        var href = window.location.href;
        var dir = href.substring(0, href.lastIndexOf('/')) + "/";
        var bgImage;
        var cElement;
        if($(".h-video").length)
        cElement = $(".h-video");
        else if($(".h-slideshow").length)
        cElement = $(".h-slideshow");
        else if($(".hero-header").not(".login-popup,.review-popup,.pm-popup").length)
        cElement = $(".hero-header").not(".login-popup,.review-popup,.pm-popup");
        bgImage = cElement.find(".hero-image").css("background-image");
        if(bgImage!=="none"){
          bgImage = bgImage.replace(dir,"");
          bgImage = bgImage.replace(' ',"").replace(' ',"").replace(' ',"").replace(' ',"").replace(' ',"");
          bgImage = bgImage.replace('url(\"',"").replace("url(\'","").replace("url(","").replace('")',"");
          bgImage = bgImage.replace("')","").replace(")","");
          cElement
          .append('<div class="bg-container bg-media"><img class="clear-image" src="' + bgImage + '"></div>');
          this.addCanvasEffect();
        }
      }
    },

    addCanvasEffect(){
      this.image = document.querySelector('.clear-image');
      this.appending = document.querySelector('.bg-container');
      this.imageCanvas = document.createElement('canvas');
      this.imageCanvasContext =  this.imageCanvas.getContext('2d');
      this.lineCanvas = document.createElement('canvas');
      this.lineCanvasContext = this.lineCanvas.getContext('2d');
      this.pointLifetime = 1000;
      this.points = [];
      if (this.image.complete) {
        this.start();
      }
      else {
        this.image.onload = this.start;
      }
    },

    resizeCanvases() {
      if(!("disableRubber" in window)){
        setTimeout(() => {
          var c = setInterval(() => {
            if($(".hero-header canvas").length){
              this.imageCanvas.width = this.lineCanvas.width = $(".hero-header canvas").parent().width();
              this.imageCanvas.height = this.lineCanvas.height = $(".hero-header canvas").parent().height();
            }
          },1);
          setTimeout(() => {
            clearInterval(c);
          },200);
        },2000);
      }
    },

    resetElements(){
      this.checkHeroFeatPosts();
      // this.startTypingEffect();
      // this.typingOnLanding();
      this.setHeroSlideshow();
    },

    prepareInterface() {
      var he = ".hero-header ",
      temp,
      he_ = $(".hero-header"),
      color_skin = $("<div class='dumb'>").appendTo("body").addClass("color_skin").css("background-color");
      $(".dumb").remove();
      $(".dumb").each(() => {
          $(this)[0].parentNode.removeChild($(this)[0]);
      });
      if($.isFunction($.fn.dragScroll))
        $('.gallery').before('<div class="wave-top-gallery"></div>').dragScroll({});
        $(".navbar-inverse .container").prepend('<div class="navbar-header"></div>');
        $(".navbar-inverse .navbar-header").append('<a class="navbar-toggle"></a>');
        $(".navbar-inverse .navbar-header .navbar-toggle")
        // .append('<span class="sr-only">Toggle</span>')
        // .append('<span class="icon-bar"></span>')
        // .append('<span class="icon-bar"></span>')
        // .append('<span class="icon-bar"></span>');
        he_.prepend('<div class="hero-image"></div>');
        he_.prepend('<div class="overlay"></div>');
        he_.prepend('<div class="dot-overlay"></div>');
        if($(".masked").length)
        $(".masked").prepend('<div class="hero-mask"></div>');
        if($(".texture").length)
        $(".texture").prepend('<div class="hero-texture"></div>');
        $(he).not(".search-popup").find(".overlay").css({display:"block"});
        $(".search-categories").append('<i class="more-categories fa fa-plus"></i>');
        $(".search-categories").append('<i class="minus-categories fa fa-minus"></i>');
        $(".listing-item-link").append('<div class="listing-black-overlay"></div>');
        if($(".search-categories .categories").length)
        if($(".search-categories .categories")[0].scrollHeight>80)
        $(".search-categories .more-categories").css({display:"block"});
        $('#slide-nav.navbar-inverse').after($('<div class="inverse" id="navbar-height-col"></div>'));
        $('#slide-nav.navbar-default').after($('<div id="navbar-height-col"></div>'));
        $(".search-popup,.review-popup,.pm-popup,.login-popup").prepend('<div class="back-site icon-arrow-left"></div>');
        $(".listing-filter input[type='submit']").attr("value","");
        $(".accord-close").attr("href","#accordion");
        $(".search-cities-toggle").delay(3000).animate({opacity: 1});
        if($(".gallery").length||$("a[data-lightbox='gallery']").length)
        $(".lightbox").css({marginLeft:0});

        this.resetElements();
        // this.setCanvasEffect();
        this.Events();
    }
  }
}