<template>
  <div v-if='showBusinessProfile && business.categories && business.categories.length > 0' class="container-fluid">
        <div class=" row text-center">
          <SideFilter class="mb-4" :productCategories='business.categories' :pageReset='pageReset'/>

          <div v-if="!categoryName == '' && !loading" class="row w-100 mb-2">
              <h2  class='filter-heading'>{{categoryName}}</h2>
              <div class="row w-100 mx-2">
                <div class=" col-12 col-sm-12 col-md-12 col-xl-6 "  v-for='item in paginatedProducts' :key='item.id'>
                    <PopProduct :show='business.show_prices' :item='item' :openModal='openModal' :large='true'/>
                </div>
              </div>
            </div>
            <NoPlaces v-else :msg="$t('noProducts')" ></NoPlaces>
            <div class="col-12">
              <Pagination             
                :showPage='showPage'
                :lastPage='lastPage'
                :currentPage='currentPage'
                class='pt-4'/>
            </div>
        </div>


        <div class="modal fade pb-9"  id='productModal'>
          <div class="mx-2 modal-dialog" :class='width == "sm" ? "w-100" : ["modal-xl", "mx-auto"]'>
            <div class="modal-content filter-contain">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body ">
                  <ProductDetails :show='business.show_prices'/>
              </div>
            </div>
          </div>
        </div>

        
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SideFilter from '@/components/Listings/SideFilter';
import Pagination from '@/components/misc/Pagination';
import PopProduct from '@/components/Listings/PopProduct';
import ProductDetails from '@/components/order/ProductDetails.vue';
import NoPlaces from '@/components/misc/NoPlaces';
export default {
  name: 'BusinessProducts',
  components: {
    SideFilter,
    PopProduct,
    Pagination,
    ProductDetails,
    NoPlaces
  },
  data() {
    return {
      showBusinessProfile: true,
      currentPage: 1,
      page_size: 16,
      categoryName: '',
    }
  },
  props: ['business', 'productParam'],
  watch:{
    business: function(newPros, olPros) {
      if(olPros.categories != newPros.categories) {
        this.getBusinessProducts({
          business_id: this.$route.params.id,
          category_id: this.business.categories[0].id,
          lang: this.$i18n.locale
        })
      }else {
        if(!this.business.categories || this.business.categories == 0) {
          this.showBusinessProfile = false;
        }else this.showBusinessProfile = true 
      }
    },
  },
  mounted() {
   if(this.$route.params.product_id && this.$route.params.product_id !== 0) {
      this.openModal(this.$route.params.product_id);
    }
  },
  computed: {
    ...mapGetters(['businessProducts', 'width', 'loading']),
    lastPage() {
      if(this.businessProducts.length / 16 < 1 || this.businessProducts.length == 16) {
        return 1;
      }else if(this.businessProducts.length / 16 > 1 && this.businessProducts.length % 16 > 0) {
        return (Math.floor(this.businessProducts.length / 16)) + 1
      }else return this.businessProducts.length / 16 
    },
    paginatedProducts() {
      return this.businessProducts.slice((this.currentPage - 1) * this.page_size, this.currentPage * this.page_size);
    },
    
  },
  methods: {
    ...mapActions(['showProduct', 'getBusinessProducts']),
    openModal(id) {
      this.showProduct({
        lang: this.$i18n.locale,
        product_id: id
      }).then(res => {
        $('#productModal').modal('show');
      })
        .catch(err => console.error(err));
    },
    showPage(page) {
      this.currentPage = page;
      return this.businessProducts.slice((this.currentPage - 1) * this.page_size, this.currentPage * this.page_size);
    },
    pageReset(name) {
      this.currentPage = 1;
      this.categoryName = name;
    },
  }
}
</script>