<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-12">
         <div 
          class="d-flex justify-content-between mb-4"
          :class="$i18n.locale == 'ar' ? 'flex-row' : 'flex-row-reverse'"
        >
          <div class="align-self-end">
            <button class="btn-icon-black" @click='swiper.slidePrev()' v-if='width != "sm"'>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button class="btn-icon-black" @click='swiper.slideNext()' v-if='width != "sm"'>
              <i class="fa fa-chevron-right"></i>
            </button>
            </div>
          <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" class="">
            <div class="header-home">
              {{$t('aroundMe')}}
            </div>
            <div class="subheader-home">
              {{$t('subheader')}}
            </div>
          </div>
        </div>
        </div>
        <div class="text-center col-12">
         <div class="row">
            <div class="col">
               <NoPlaces v-if='!exist' :msg="$t('noPlaces')"/>

              <Swiper class="swiper" ref='AroundMeSlider' v-else :options='swiperOptions' :dir="$i18n.locale == 'ar' ? 'rtl': ''">
                <SwiperSlide 
                  class="col-12 col-sm-9 col-md-8 col-lg-6 col-xl-4" 
                  
                  v-for='item in aroundMe' 
                  :key=item.id
                >
                  <div>
                    <Highlited :item='item' />
                  </div>
                </SwiperSlide>  
              </Swiper>
            </div>
         </div>

        </div>
    </div>
  </section>
</template>

<script>
import NoPlaces from '@/components/misc/NoPlaces.vue';
import { helpers } from '../Helpers';
import Highlited from './Listings/Highlited.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'AroundMe',

  mixins: [helpers],
  components: {
    NoPlaces,
    Highlited,
    Swiper, 
    SwiperSlide
  },

  data() {
    return {
       swiperOptions: {
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
    }
  },

  props: ['aroundMe', 'exist'],
  computed: {
    width() {
      return this.$store.getters.width;
    },
    swiper() {
      return this.$refs.AroundMeSlider.$swiper;
    }
  },
}
</script>

<style scoped>
.avatar{
  border-radius: 50%;
  width:100px;
}
.cat-icon{
  top: -35px;
  left: -30px;
}
</style>

