<template>
  <form  class="review-form" @submit.prevent='submitReview'>
    <div class="form-group">
      <textarea 
        class="form-control" 
        rows="5" 
        :placeholder="$t('DescribeExp')"
        :class="ar ? 'dir-rtl' : 'dir-ltr' "
      >
      </textarea>
    </div>
    <div class="form-group">
      <div 
        class="star-rating"
        :class="ar ? ['text-right', ''] : ['text-left', ''] "  
      >
        <fieldset>
          <input type="radio" id='star5' v-model='rate' value="5"><label for="star5" :class="ar ? 'f-left' : 'f-right' " @click.prevent='setRate(5)' title="Outstanding">5 stars</label>
          <input type="radio" id='star4' v-model='rate' value="4"><label for="star4" :class="ar ? 'f-left' : 'f-right' " @click.prevent='setRate(4)' title="Very Good">4 stars</label>
          <input type="radio" id='star3' v-model='rate' value="3"><label for="star3" :class="ar ? 'f-left' : 'f-right' " @click.prevent='setRate(3)' title="Good">3 stars</label>
          <input type="radio" id='star2' v-model='rate' value="2"><label for="star2" :class="ar ? 'f-left' : 'f-right' " @click.prevent='setRate(2)' title="Poor">2 stars</label>
          <input type="radio" id='star1' v-model='rate' value="1"><label for="star1" :class="ar ? 'f-left' : 'f-right' " @click.prevent='setRate(1)' title="Very Poor">1 star</label>
        </fieldset>
      </div>
    </div>
    <div 
      class="form-group"
      :class="ar ? 'f-right' : 'f-left' "
    >
      <input type="submit" class="btn-pri-lg" value="Leave Feedback">
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'WriteReview',
  data() {
    return {
      rate: 0,
      review: '',
      business_id: this.$route.params.id,
      stars: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
      ]
    }
  },
  methods: {
    ...mapActions(['postReview']),
    submitReview() {
      // this.postReview({
      //   business_id: this.business_id,
      //   review: this.review,
      //   rate: this.rate
      // }).then(res => console.log(res))
      //   .catch(err => console.log(err))
      console.log(this.rate)
    },
    setRate(rate) {
      this.rate = rate;
      console.log(rate)
    }
  },
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }
}

</script>