<template>
  <div>
    <ul class="nav nav-tabs modal-tabs" :class="flex_ar_reverse">
      <li
        :class="openedTab == 'settings' ? 'active' : ''" 
        class="nav-item modal-tabs-link" 
        @click='changeTab("settings")'
      >
        {{$t('CookiesSettings')}}
      </li>
      <li
        :class="openedTab == 'declare' ? 'active' : ''"  
        class="nav-item modal-tabs-link" 
        @click='changeTab("declare")'
      >
        {{$t('CookiesDeclaration')}}
      </li>
    </ul>

    <CookiesSettings v-if='openedTab == "settings"'/>
    <CookiesDeclare v-if='openedTab == "declare"'/>

    <button type="button" class=" btn-pri col-12" data-dismiss="modal" >
      {{$t('SaveSettings')}}
    </button>
  </div>
</template>

<script>
import CookiesSettings from '@/components/misc/CookiesSettings.vue';
import CookiesDeclare from '@/components/misc/CookiesDeclare.vue';
export default {
  components: {
    CookiesSettings,
    CookiesDeclare
  },
  data() {
    return {
      openedTab: 'settings'
    }
  },
  methods: {
    changeTab(id) {
      this.openedTab = id;
    }
  },

   computed: {
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row': 'flex-row-reverse';
    },
    flex_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': 'flex-row';
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left': 'text-right';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
  }
}
</script>