<template>
  <div class="container-fluid">
    <div 
      class="row" 
      :class="[containType, width == 'sm' ? 'mb-8' : '', $i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row']" 
      v-if='msgOptions.status'
    >
      <div class="col-12 col-md-7 align-self-center">
        <div :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'">
          <i class="fa fa-check-circle" v-if='msgOptions.type == "success"'></i>
          <i class="fa fa-warning" v-else-if='msgOptions.type == "error"'></i>
          <i class="fa fa-info-circle" v-else-if='msgOptions.type == "warning"'></i>
          <span class="msg-text m-2" >
            {{msgOptions.text}}
          </span>
        </div>
        
      </div>
      <div  
        class="col-12 col-md-5 " 
        :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
      >
        <button 
          class="  mt-2"  
          :class="msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'" 
          v-if='msgOptions.condition == 3' 
          @click="$router.push({name: 'Privacy'})"
        >
          {{$t('privacy')}}
        </button>
        <!-- <button 
          class="  mt-2"  
          :class="msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'" 
          v-if='msgOptions.condition == 3' 
          @click="$store.state.cookiesModal=true;showModal()"
        >
          {{$t('CookiesSettings')}}
        </button> -->
        <button 
          class="btn-def-sm  mt-2"  
          v-if=' msgOptions.condition == 6 || msgOptions.condition == 4 || msgOptions.condition == 5' 
          @click="closeMsg(1)"
        >
          {{$t('Close')}}
        </button>
        <button 
          class="  mt-2" 
          :class=" msgOptions.condition == 3 ? 'btn-pri-sm' : 'btn-def-sm'"  
          v-if='msgOptions.close' 
          @click="closeMsgModal"
        >
          {{$t('accept')}}
        </button>
      </div>
      <div
        v-if='msgOptions && msgOptions.condition == 3'
        :class="[$i18n.locale == 'ar' ? 'text-right' : 'text-left',]"
        class="my-4 px-3"
      >
        {{msgOptions.desc}} 
        <div class="data-label text-underline pointer" >{{$t('LearnMore')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import DownloadApp from '@/components/misc/DownloadApp';
export default {
  components: {
    DownloadApp,
  },
  props: ['showModal'],
  computed: {
    ...mapGetters(['msgOptions', 'width']),
    containType() {
      if (this.msgOptions.type == 'success') {
        return 'msg-contain-success';
      }else if (this.msgOptions.type == 'error') {
        return 'msg-contain-error';
      }else if (this.msgOptions.type == 'warning') {
        return 'msg-contain-warning';
      }else {
        return 'msg-contain';
      } 
    }
  },
  methods: {
    ...mapActions(['closeMsg']),
    ...mapMutations(['showMsg']),
    closeMsgModal() {
      this.closeMsg(this.msgOptions.condition)
        .then(res => {
          if (res == 3) {
            console.log('Cookies Used')
          }else if (res == 4) {
            $('#productModal').modal('hide');
            $('#profileModal').modal('hide');
          }else if (res == 5) {
            console.log('Cart Emtied')
          }else if (res == 6) {
            $('#orderModal').modal('hide');
          }
        }).catch(err => {
          this.showMsg({
            close: false,
            text: this.$t('someWrong'),
            type: 'error'
          })
        })
    }
  }
}
</script>