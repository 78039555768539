<template>
  <div>
    <div class="">
      <i v-for='(star, index) in useStars' :key='index' class="fa fa-star stars"></i>
      <i v-for='(star, index) in halfs' :key='index+3' class="fa fa-star-half-o stars"></i>
      <i v-for='(star, index) in rest' :key='index+5' class="fa fa-star-o stars"></i>    
    </div>
    <!-- {{ rest }} -->
  </div>
</template>

<script>
export default {
  name: 'Stars',
  props: [ 'stars' ],

  data() {
    return {
      useStars: parseInt(this.stars, 10),
    }
  },

  computed: {
    halfs() {
      return this.useStars % 1 > 0 ? 1 : 0;
    },
    rest() {
      return Math.floor(5 - this.useStars);
    },  
    zerStars() {
      return this.useStars > 0
    }
  },

}
</script>