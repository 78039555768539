<template>
  <div class="container-fluid listing-block related" id="rel">
        <!-- <div class="pg-header-icon icon-link"></div> -->
        <h2 class="text-left">Related Listings</h2>
        <div class="row listing white my-8">
          <!-- Start Listing Item Col -->
          <div class="col-sm-3">
            <div class="listing-item">
              <a href="#" class="category-icon icon-dinner"></a>
              <div class="listing-item-rating">4.9</div>
              <a href="#" class="listing-item-link">
                <div class="listing-item-title-centralizer">
                  <div class="listing-item-title">
                    Area Four
                    <div class="ribbon">~ $18-$90</div>
                  </div>
                </div>
                <img alt="" src="assets/images/listings/1.jpg" />
              </a>
              <div class="listing-item-data">
                <a class="listing-item-address" href="#">
                  Troy South End, 264 E. Berkley St, Boston
                </a>
                <div class="listing-item-excerpt">
                  Excellent wood-fired pizzas, salads, and croissants
                </div>
              </div>
              <div class="listing-category-name">
                <a href="#">Restaurant</a>
              </div>
            </div>
          </div>
          <!-- End Listing Item Col -->
        </div>
      </div>
</template>


<script>
import { helpers } from '../../Helpers';
export default {
  name: 'Profile',
  props: [ 'business' ],
  mixins: [ helpers ]
}
</script>