import { render, staticRenderFns } from "./OrderNow.vue?vue&type=template&id=0842ae36&scoped=true&"
import script from "./OrderNow.vue?vue&type=script&lang=js&"
export * from "./OrderNow.vue?vue&type=script&lang=js&"
import style0 from "./OrderNow.vue?vue&type=style&index=0&id=0842ae36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0842ae36",
  null
  
)

export default component.exports