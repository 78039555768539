<template>
  <div class="container-fluid search-contain p-2 p-md-3 pr-lg-4 ">
    <div class="row justify-content-center ">
      <form @submit.prevent='search(text, cityId)' class='col-12'>
        <div class="row" :class="ar ? 'flex-row-reverse' : 'flex-row'"  >
          <div class="form-group col-8 col-sm-9 col-md-5 col-lg-5 col-xl-6"
        >
          <label 
            class="home-search-label"
            :class="ar ? 'f-right' : 'f-left'"
          >
            {{$t('find')}}
          </label>
          <input 
            :class="ar ? 'text-right' : 'text-left'"
            class='form-control home-text-input' 
            v-model='text'
            :placeholder="$t('line1')"  
          />
        </div>

        <!-- <div class="ver-divider col-md-1"></div> -->

        <div class="form-group col-8 col-md-5 " v-if=" width != 'sm'">
          <label 
            class="home-search-label"
            :class="ar ? 'f-right' : 'f-left'"
          >
            {{$t('location')}}
          </label>
          <select 
            v-model="cityId" 
            name='type' 
            id='type' 
            class='form-control home-text-input p-2'
          >
            <option value='' disabled selected> {{$t('PlaceCity')}} </option>
            <option value=''> None </option>  
            <option v-for="city in cities" :key="city.id" :value='city.id'> {{city.name}} </option>
          </select>
        </div>
        
        <div 
          class='col-4 mt-2 col-sm-3 col-md-2 col-lg-2 col-xl-1'
        >
          <button class='home-submit w-100' type='submit'>
            <i class='fa fa-search'></i>
          </button>
        </div>
        </div>
     
        <div class="justify-content-center row" v-if='width != "sm" && getInspired'>
            <Chip 
              class="pointer"
              v-for='item in getInspired.slice(1)' :key='item.id'
              :item='item'
              :goSearchResults='goSearchResults'
            />      
        </div>
    </form>

    
    </div>   
  </div>
</template>


<script>
import Chip from '@/components/misc/Chip';
export default {  
  components: {
    Chip
  },
  data() {
    return {
      text: '',
      cityId: ''
    }
  },
  mounted() {
    this.$store.dispatch('getSearchResults', {
      lang: this.$i18n.locale
    })
  },
  props: ['search', 'getInspired', 'goSearchResults'],
  computed: {
    cities() {
      return this.$store.getters.cities
    },
    ar() {
      return this.$i18n.locale == 'ar';
    },
    width() {
      return this.$store.getters.width;
    }
  },
}
</script>