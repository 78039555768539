<template>
  <div class="card card-item container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card-item-image" @click='goProfile(item.id)'>
          <img :src="item.media.length > 0 ? item.media[0].url : 'https://media.tarkett-image.com/large/TH_24567081_24594081_24596081_24601081_24563081_24565081_24588081_001.jpg'" class="card-img-top">
        </div>
        <!-- <div class='item-favourite'>
          <i class="fa fa-heart-o"></i>
        </div> -->
        <div class="item-address">
          <button class='btn-pri text-ellipsis'>{{item.area}}</button>
        </div>
        <div class="item-directions">
          <button class="btn-def" @click='goMap(item.location.address_link)'>
            <i class="fa fa-arrows"></i>
            Directions
          </button>
       </div>
      </div>
    </div>
    
    <div class="card-body card-details" :class="ar ? 'text-right' : 'text-left'">
      <div class="card-title details-title mb-n1">
        <div class="d-flex justify-content-between " :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row-reverse'">
          <button class="btn-icon-pri-lg " @click='goPhone(item.location.phone)'>
            <i class="fa fa-phone"></i>
          </button>  
          <div class="text-ellipsis" @click='goProfile(item.id)'>{{item.name}}</div>
        </div>
      </div>
      <div class="card-text details-categories text-underline text-ellipsis">{{htmlDecode(item.category.name)}}</div>
      <Stars :stars='item.rate'/>
    </div>
  </div>
</template>

<script>
import Stars from '@/components/misc/Stars';
import { helpers } from '../../Helpers';

export default {
  components: {
    Stars
  },
  mixins: [helpers],
  props: ['item'],

  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  },

  methods: {
    goReserve(id) {
      this.$router.push({
        path: `/${this.$i18n.locale}/${id}`,
      })
    },
    goProfile(id) {
      this.$router.push({
        name: 'BusinessProfile',
        params: {
          locale: this.$i18n.locale,
          id: id,
        }
      })
    },
    goMap(link) {
      window.location.href = link;
    },
    goPhone(link) {
      window.location.href = `tel:${link}`;
    }
  }

}
</script>