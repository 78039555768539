<template>
  <div class="container-fluid mx-2">
        <div class="row mt-4" >
          <div class="col-12">
              
            <div class="mx-2 row d-flex mt-4" :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : 'flex-row'">
              <div class="col-12 col-sm-12 col-lg-9">
                <div>
                    <h3 class=' listing-section '
                      :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
                    >{{$t('info')}}</h3>
                    <p class="opt-label height-info-protect" :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'" v-if='business.location.cleardescription'>
                      {{business.location.cleardescription}}
                    </p>
                    <NoPlaces v-else :msg="$t('noInfo')"  />
                </div>
                <Amenities :business='business' class="px-n3 my-3"/>
              </div>
              <div class="socials-card col-12 col-sm-12 col-lg-3 p-2 pt-5 p-md-4 pt-md-5 ">
                  <div class="text-center">
                    <button :href="business.location.facebook" :disabled='!business.location.facebook' class="btn-icon-pri">
                      <i class="fa fa-facebook"></i>
                    </button>
                    <button :href="business.location.twitter" :disabled='!business.location.facebook' class="btn-icon-pri">
                      <i class="fa fa-twitter"></i>
                    </button>
                    <button :href="business.location.youtube" :disabled='!business.location.facebook' class="btn-icon-pri">
                      <i class="fa fa-youtube"></i>
                    </button>
                  </div>
                <div class="list-data mt-5">
                  <div class="list-address pointer text-underline" @click='goLink(business.location.address_link)'>
                    {{business.location.address}}
                  </div>
                  <div class="list-phones pointer ">
                    <div class="list-phone pointer text-underline" @click="goPhone(business.location.phone)">
                      {{business.location.phone}}
                    </div>
                  </div>
                  <div class="list-online-data">
                    <div class="list-website pointer">
                      <a :href="business.location.website" target="_blank">
                        {{$t('website')}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class=" row mt-8 mb-5 mx-2" >
          <div class="col-12">
            <h3 class='listing-section '
            :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
            >{{$t('hotDeals')}}</h3>
            <div class="row" v-if='hotDeals.length > 0'>
              <div class="col-12 col-md-6 col-lg-4 col-xl-3 pointer" v-for='item in hotDeals' :key='item.id'>
                <PopProduct :show='business.show_prices' :item='item' :openModal='openModal' :large='false'/>
              </div>
            </div>
            <div v-else class='col-xs-12'>
              <NoPlaces :msg="$t('noHotDeals')" />
            </div>
          </div>
        </div>

        <div class="mt-8 mx-2 row" v-if='business.reviews.length > 0'>
          <div class="col-12">
            <h3 class='listing-section '
              :class="$i18n.locale == 'ar' ? 'text-right' : 'text-left'"
            >
              {{$t('reviews')}}
              <span class="rating-no ">({{business.reviews.length}})</span>
            </h3>
            <div class="row justify-content-center" v-if='business.reviews.length > 0'>
              <!-- <WriteReview /> -->
              <!-- Start Review Author Block -->
              <div class="col-12   my-2" v-for='rev in business.reviews.slice(0,5)' :key='rev.id'>
                  <Review :item='rev' />
              </div>
              <button class="btn-pri-lg mt-4" :class="$i18n.locale == 'ar' ? 'f-right' : 'f-left'" @click='changeTab("rev")'>{{$t('readMore')}}</button>
              <!-- End Review Author Block -->
            </div>
            <div v-else class='col-xs-12'>
              <NoPlaces :msg="$t('noReviews')" />
            </div>
          </div>
        </div>

        <div class="modal fade pb-9"  id='profileModal'>
          <div class="mx-2 modal-dialog" :class='width == "sm" ? "w-100" : ["modal-xl", "mx-auto"]'>
            <div class="modal-content filter-contain">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body ">
                  <ProductDetails :show='business.show_prices'/>
              </div>
            </div>
          </div>
        </div>

      </div>
</template>

<script>
import Amenities from '@/components/Listings/Amenities';
import Review from '@/components/Listings/Review.vue';
import NoPlaces from '@/components/misc/NoPlaces.vue';
import PopProduct from '@/components/Listings/PopProduct';
import { mapActions } from 'vuex';
import ProductDetails from '@/components/order/ProductDetails.vue';

import { helpers } from '../../Helpers';
export default {
  name: 'Profile',
  components: {
    Amenities,
    Review,
    PopProduct,
    NoPlaces,
    ProductDetails
  },
  mounted() {
  },
  props: [ 'business', 'hotDeals', 'changeTab' ],
  mixins: [ helpers ],
  methods: {
    ...mapActions(['showProduct']),
    goLink(link) {
      window.location.href = link;
    },
    openModal(id) {
      $('#profileModal').modal('show');
      this.showProduct({
        lang: this.$i18n.locale,
        product_id: id
      }).then()
        .catch(err => console.error(err));
    },
    goPhone(link) {
      window.location.href = `tel:${link}`;
    }
  },
  computed: {
    width() {
      return this.$store.getters.width;
    }
  }
}
</script>

