<template>
  <div class="no-places">
    <h2 v-if='!loading && !modalLoading && !profileLoading'>{{msg}}</h2>
    <h2 v-else>Waiting For The Data .... !!</h2>
    <img v-if='loading || modalLoading || profileLoading' height='120px' src='../../assets/images/logo/loading.gif' />
  </div>
</template>

<script>
export default {
  props: ['msg'],
  computed: {
    isMobile() {
      return this.$store.getters.width == 'sm';
    },
    loading() {
      return this.$store.getters.loading;
    },
    profileLoading() {
      return this.$store.getters.profileLoading;
    },
    modalLoading() {
      return this.$store.getters.modalLoading;
    },
  }
}
</script>