<template>
  <div class="card rev-contain mb-3">
    <div class="row no-gutters d-flex" :class="ar ? 'flex-row-reverse' : 'flex-row'">
      <div class="col-12 col-md-2 col-xl-1 mt-3">
        <img class="rev-image" :src="item.user && item.user.media.length > 0 ? item.user.media[0].icon : 'https://www.pngitem.com/pimgs/m/592-5922762_user-icon-material-design-hd-png-download.png'" />
      </div>
      <div class="col-12 col-md-10 col-md-11">
        <div class="card-body px-5">
          <div class=" rev-name" :class="ar ? 'text-right' : '' " :stars='item.rate'>{{item.user ? item.user.name : 'Guest User'}}</div>
          <div class="my-2" :class="ar ? 'text-right' : '' " v-html="item.review"></div>
          <Stars :class="ar ? 'text-right' : 'text-left' " :stars='item.rate' />
          <span class="card-text rev-created" :class="ar ? 'text-left' : 'f-right' ">{{item.updated_at.split(' ')[0]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from '@/components/misc/Stars';

export default {
  components: {
    Stars
  },
  props: ['item'],
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }
}
</script>