import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import Axios from 'axios';
import cookies from 'vue-cookies';
import { i18n } from '../i18n';

import search from './search';
import listings from './listings';
import auth from './auth';
import order from './order';
import guest from './guest';
import paypal from './paypal';


Vue.use(Vuex);
console.log("process.env.VUE_APP", process.env.VUE_APP_TEST);
window.isTest = process.env.VUE_APP_TEST || false; //False in Live Env خليها زى ما هى واستخدم ملف .env عشان عملت معايا مشكله على السرفر //>>>>> npm run env

// console.log("process.env.APP_URL", process.env.VUE_APP_URL);
window.baseURL = process.env.VUE_APP_URL || (window.isTest ? 'https://test.snawnaw.com/public/' : 'https://app.snawnaw.com/');
window.baseApi = 'api/';
Axios.defaults.baseURL = window.baseURL+window.baseApi;



export default new Vuex.Store({
  state: {
    businessCategories: [],
    getInspired: [],
    aroundMe: [],
    orderNow: [],
    events: [],
    hotDeals: [],
    features: [],
    width: '',
    searchForm: false,
    msgOptions: {
      status: false,
      type: '',
      close: false,
      text: '',
      condition: 3,
      business_id: '',
      item: {},
      orderId: '',
      desc: '',
    },
    loading: false,
    modalLoading: false,
    profileLoading: false,
    businessHold: {},
    slides:[],
    cookiesModal: false,
    outerCat: {},
  },
  getters: {
    businessCategories: (state) => state.businessCategories,
    getInspired: (state) => state.getInspired,
    aroundMe: (state) => state.aroundMe,
    orderNow: (state) => state.orderNow,
    events: (state) => state.events,
    hotDeals: (state) => state.hotDeals,
    slides: (state) => state.slides,
    features: (state) => state.features,
    width: (state) => {
      if( state.width < 767 ) {
        return 'sm'
      }else if( state.width < 992 ){
        return 'md'
      }else {
        return 'lg'
      }
    },
    widthPx: (state) => state.width,
    msgOptions: (state) => state.msgOptions,
    loading: (state) => state.loading,
    modalLoading: (state) => state.modalLoading,
    profileLoading: (state) => state.profileLoading,
    cookiesModal: (state) => state.cookiesModal,
    outerCat: (state) => state.outerCat,
  },
  mutations: {
    setBusinessCategories(state, payload) {
      state.businessCategories = [...payload];
    },
    setGetInspired(state, payload) {
      state.getInspired = [...payload];
    },
    setAroundMe(state, payload) {
      state.aroundMe = [...payload];
    },
    setOrderNow(state, payload) {
      state.orderNow = [...payload];
    },
    setEvents(state, payload) {
      state.events = [...payload];
    },
    setHotDeals(state, payload) {
      state.hotDeals = [...payload];
    },
    setFeatures(state, payload) {
      state.features = [...payload];
    },
    showMsg(state, payload) {
      state.msgOptions.status = true;
      state.msgOptions.close = payload.close;
      state.msgOptions.text = payload.text;
      state.msgOptions.type = payload.type;
      state.msgOptions.condition = payload.condition || 1;
      state.msgOptions.business_id = payload.business_id;
      state.msgOptions.item = payload.item;
      state.msgOptions.orderId = payload.orderId;
      state.msgOptions.desc = payload.desc;
      
      if (payload.close != true) {
        setTimeout(() => {
          state.msgOptions.status = false;
        }, 3000)
      }
    },
    loadingOn(state) {
      state.loading = true;
    },
    loadingOff(state) {
      state.loading = false;
      state.modalLoading = false;
      state.profileLoading = false;
    },
    modalLoadingOn(state) {
      state.modalLoading = true;
    },
    // modalLoadingOff(state) {
    //   state.modalLoading = false;
    // },
    profileLoadingOn(state) {
      state.profileLoading = true;
    },
    // profileLoadingOff(state) {
    //   state.profileLoading = false;
    // },
    setSlides(state, payload) {
      state.slides = [...payload]
    },
    // setCookiesModal(state) {
    //   state.cookiesModal = true;
    // }
  },
  actions: {
    getHomeData({commit}, payload) {
      let params = {};
        if(payload.longitude !== '') {
          params = {
            lang: payload.locale,
            longitude: payload.longitude,
            latitude: payload.latitude,
            // longitude:  9.9997432,
            // latitude:53.5509903,
          }
        }else params = { lang: payload.locale };

      return new Promise((resolve, reject) => {
        Axios.post(`getHome`, null, {
          params
        }).then(res => {        
          if(res.data.data.getInspired) {
            commit('setGetInspired', res.data.data.getInspired);
          }
          if(res.data.data.aroundMe) {
            commit('setAroundMe', res.data.data.aroundMe);
          }
          if(res.data.data.orderNow) {
            commit('setOrderNow', res.data.data.orderNow);
          }
          if(res.data.data.events) {
            commit('setEvents', res.data.data.events);
          }
          if(res.data.data.hotDeals) {
            commit('setHotDeals', res.data.data.hotDeals);
          }
          if(res.data.data.features) {
            commit('setFeatures', res.data.data.features);
          }
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      })
    },
    closeMsg({dispatch, commit, state}, payload) {
      state.msgOptions.status = false;
      return new Promise((resolve, reject) => {
        if(payload == 3) {
          cookies.set('agreed', true);
          resolve(3);
        }else if(payload == 4) {
          cookies.remove('items');
          cookies.set('BusId', state.msgOptions.business_id);
          let items = [];
          items.push(state.msgOptions.item);
          cookies.set('items', JSON.stringify(items));
          commit('showMsg', {
            close: false,
            text: 'Added to Cart Successfully',
            type: 'success',
          });
          resolve(4);
        }else if(payload == 5){
          commit('destroyVCart');
          dispatch('getAllCartItems');
          commit('showMsg', {
            close: false,
            text: 'Cart Has Been Cleared',
            type: 'success',
          });
          resolve(5);
        }else if (payload == 6) {
          dispatch('cancelOrder', state.msgOptions.orderId)
          .then(res => {
            commit('showMsg', {
              close: false,
              text: 'Order Has been Cancelled',
              type: 'success',
            })
          }).catch(err => reject(err))
          dispatch('getAllCanceled');
          resolve(6);
        }else if (payload == 7) {
          
        }
      })
    },
    startLoading({commit}) {
      commit('loadingOn');
    },
    stopLoading({commit}) {
      commit('loadingOff')
    },
    startModalLoading({commit}) {
      commit('modalLoadingOn');
    },
    // stopModalLoading({commit}) {
    //   commit('modalLoadingOff')
    // },
    startProfileLoading({commit}) {
      commit('profileLoadingOn');
    },
    // stopProfileLoading({commit}) {
    //   commit('profileLoadingOff')
    // }
    getSlides({commit}, payload = 'website_home_sliders') {
      return new Promise((resolve, reject) => {
        Axios.post(`slider/${payload}`)
          .then(res => {
            if ( res.data.success == true ) {
              commit('setSlides', res.data.data);
              resolve(res);
            }
          }).catch(err => reject(err))
      })
    },
    // openSettings({commit}) {
    //   return new Promise((resolve, reject) => {
    //     commit('setCookiesModal')
    //   }).then(res => {
    //     resolve('open')
    //   }).catch(err => reject('close'))
    // }
  },
  
  modules: {
    search,
    listings,
    auth,
    order,
    guest,
    paypal
  }
})
